import 'bootstrap/dist/css/bootstrap.css';
import './scheduling.scss';

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import config from '../../config/config';
import { selectLanguage } from '../../reduxToolkit/features/languageSlice';
import { toastError } from '../../services/appService';
import {
    ContentDictionary, getContentDictionaryInCurrentLanguage, getEnumOptions
} from '../../services/contentService';
import { getPersonalizedSchedulingUrl } from '../../services/userService';
import { addQueryParameter } from '../../services/utilities';
import { BlockItResponse, EnumOption } from '../../types/interfaces';
import { PatientSchedulingCategory } from '../../types/types';
import MyMhSpinner from '../shared/Spinner/MyMhSpinner';

// import { isMobileApp } from '../../services/utilities';
// import MyMhBanner from '../MyMhBanner/MyMhBanner';
// import { redirectToUrl, setViewFlag } from '../../services/utilities';

interface PageHeader {
  header: string;
  description: string;
}


const SchedulingGuide = ({
  category, service
}: {
  category: PatientSchedulingCategory;
  service?: string;
}) => {
  // setViewFlag(searchParams);

  const history = useHistory();

  const [callingApi, setCallingApi] = useState<boolean>(false);
  const [apiError, setApiError] = useState<string | undefined>(undefined);
  const [contentDictionary, setContentDictionary] =
    useState<ContentDictionary | null>(null);

  const [pageHeader, setPageHeader] = useState<PageHeader | undefined>(
    undefined,
  );
  const [services, setServices] = useState<EnumOption[]>([]);

  const language = useSelector(selectLanguage);

  const extractContextTokenFromUrl = () => {
    const queryParams = new URL(window.location.href).searchParams;
    const contextToken = queryParams.get('context') || queryParams.get('Context');
    return contextToken;
  };

  const routeToSchedulingScreen = (data?: BlockItResponse) => {
    let url = config.urlConfig.scheduling;
    if (data) {
      // if (data.token) {
      //   url = `${url}?token=${data.token}`;
      // }
      if (data.url) {
        url = `${url}?frameSrc= ${encodeURIComponent(data.url)}`;
      }
      if (data.skipPrescreen) {
        //url = `${url}${token ? '&' : '?'}noBack=true&skipPrescreen=true`;
        url = addQueryParameter(url, 'skipPrescreen', 'true');
      }
      if (category === 'GeneralScheduling' || category === 'TicketScheduling') {
        url = addQueryParameter(url, 'noBack', 'true');
      }
    }
    history.push(url);
  };

  const handleSchedulingWithContextOrService = (
    context: string | null,
    service?: string,
  ): void => {
    setApiError(undefined);
    setCallingApi(true);
    getPersonalizedSchedulingUrl(category, context, service)
      .then((result) => {
        if (result.ok) {
          routeToSchedulingScreen(result.data);
        } else {
          // setApiError('Failed to get personalized scheduling url');
          console.error(`Failed to get personalized scheduling url due to: ${result.message || 'Unknown cause'}`);
          // history.push(config.urlConfig.scheduling);
          routeToSchedulingScreen();
        }
      })
      .catch((err) => {
        // setApiError('Failed to get personalized scheduling url');
        console.error(
          'Failed to get personalized scheduling url due to unexpected exception: ',
          err,
        );
        // history.push(config.urlConfig.scheduling);
        routeToSchedulingScreen();
      })
      .finally(() => setCallingApi(false));
  };


  const doScheduling = (service?: string) => {
    const contextToken = extractContextTokenFromUrl();
    if (contextToken || service) {
      handleSchedulingWithContextOrService(contextToken, service);
    } else {
      console.error(
        'Failed to get personalized scheduling url because no context token had been provided.',
      );
      // history.push(config.urlConfig.scheduling);
      routeToSchedulingScreen();
    }
  };

  useEffect(() => {
    if (category !== 'GeneralScheduling' && category !== 'TicketScheduling') {
      const content = getContentDictionaryInCurrentLanguage('scheduling');
      if (!content) {
        console.warn('Failed to load the content dictionary.');
      } else {
        setContentDictionary(content);
        switch (category) {
          case 'ImagingScheduling':
            setPageHeader({
              header: content.imageing,
              description: content.imageingNote,
            });
            break;
          case 'ScreeningScheduling':
            setPageHeader({
              header: content.screening,
              description: content.screeningNote,
            });
            break;
          case 'TherapyScheduling':
            setPageHeader({
              header: content.therapy,
              description: content.therapyNote,
            });
            break;
          case 'ProgramsAndClasses':
          default:
            console.error(`Unsupported category: ${category}`);
        }
      }
    }
  }, [language]);

  useEffect(() => {
    if (category === 'GeneralScheduling') {
      doScheduling();
    } else if (category === 'TicketScheduling') {
      doScheduling(service);
    } else {
      setApiError(undefined);
      setCallingApi(true);
      getEnumOptions(category)
        .then((items: EnumOption[]) => {
          if (items.length == 0) {
            setApiError('Failed to retrieve the service list from the API.');
          }
          setServices(items);
        })
        .catch((err) => {
          setApiError('Failed to retrieve the service list from the API.');
          console.error(err);
        })
        .finally(() => {
          setCallingApi(false);
        });
    }
  }, [category, service]);

  if (!contentDictionary || !pageHeader || callingApi) {
    return <MyMhSpinner />;
  }

  if (apiError) {
    console.error(apiError);
    toastError(apiError);
  }

  return (
    <div className='w-100 mt-32 padding-x d-flex flex-column justify-content-start align-items-center'>
      <h2 className='d-none'>Scheduling Guide</h2>
      <div className='w-100 d-flex flex-column justify-content-start align-items-start'>
        <div className='w-100 text-left page-header'>{pageHeader.header}</div>
        <div className='w-100 text-left page-header-description'>
          {pageHeader.description}
        </div>
      </div>
      <div className='service-list container'>
        <div className='w-100 row g-0'>
          {services.map((service, index) => (
            <div className='service-item-container col-xs-12 col-md-6 col-lg-4' key={index}>
              {/* <div
                    className='service-item'
                    onClick={() => gotoScheduling(service)}
                  >
                    <div>{service.display || service.value}</div>
                    <img className='service-item-arrow' src={caret} />
                  </div> */}
              <button
                className='service-item-btn btn primary-green'
                onClick={() => doScheduling(service?.value)}
              >
                {service.display || service.value}
              </button>
            </div>
          ))}
        </div>
      </div>
      <div className='w-100 helpline text-center'>
        {contentDictionary?.helpLineText}{' '}
        <span className='call-block'>
          <span className='call-text'>Call</span>{' '}
          <a className='link' href={`tel:${contentDictionary?.helplineNumber}`}>
            {contentDictionary?.helplineNumberText}
          </a>
        </span>
      </div>
    </div>
  );
};

export default SchedulingGuide;
