import purify from 'dompurify';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectLanguage } from '../../../reduxToolkit/features/languageSlice';
// import { useHistory } from 'react-router';
// import config from '../../../config/config';
import {
    ContentDictionary, getContentDictionaryInCurrentLanguage
} from '../../../services/contentService';
import { getUrlWithLanguage, isMobileApp } from '../../../services/utilities';
import { LocationDetails } from '../../../types/interfaces';

// import { useHistory } from 'react-router';

interface Props {
  getCareItem: LocationDetails;
}

const GetCareCard = ({ getCareItem }: Props): JSX.Element => {
  const {
    displayName,
    imageUrl,
    locationDescription,
    url,
    typicalCost,
    typicalCostNotes,
    typicalWait,
    typicalWaitNotes,
    services,
  } = getCareItem;

  // const history = useHistory();
  const language = useSelector(selectLanguage);
  const [contentDictionary, setContentDictionary] =
    useState<ContentDictionary | null>(null);

  useEffect(() => {
    const content = getContentDictionaryInCurrentLanguage('getQuickCare');
    if (!content) console.warn('Failed to load the content dictionary.');

    setContentDictionary(content);
  }, [language]);

  // const history = useHistory();
  // const gotoUrl = (url?: string) => {
  //   if (url) {
  //     url = getUrlWithLanguage(url);
  //     const isAbsolute = isAbsoluteUrl(url);
  //     if (isAbsolute) { redirectToUrl(url); }
  //     else {
  //       history.push(url);
  //     }
  //   }
  // };

  const browserView = !isMobileApp();

  return (
    <div className={`col-12 g-0 mb-16 ${browserView ? 'col-lg-6' : ''}`} >
      <div className={`getCareNow-card ${browserView ? 'browser-view' : ''}`}>
        <div className='getCareNow-image'>
          <img src={imageUrl} className='card-img-top image-top' alt={`Image for ${displayName}`} loading='lazy' />
        </div>

        <div className='getCareNow-card-body'>
          <div className='getCareNow-content-title-link'>
            <a
              href={url ? getUrlWithLanguage(url) : undefined}
              // onClick={() => gotoUrl(url)}
              className='getCareNow-content-title'
              aria-label=''
              rel='noreferrer'>
              {displayName}
            </a>
          </div>
          <div className='getCareNow-body-header'>
            <div
              dangerouslySetInnerHTML={{
                __html: purify.sanitize(locationDescription!),
              }}
            />
          </div>
          <div className='getCareNow-body-content'>
            <div className='getCareNow-cost-wait'>
              <div className='h4'>{contentDictionary?.typicalCostLabel}</div>
              <span>{typicalCost}</span>
              <span className='note'>{typicalCostNotes}</span>
              <hr />
              <div className='h4'>{contentDictionary?.typicalWaitLabel}</div>
              <span>{typicalWait}</span>
              <span className='note'>{typicalWaitNotes}</span>
            </div>
            {services && (
              <div
                className='getCareNow-list'
                dangerouslySetInnerHTML={{ __html: purify.sanitize(services) }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetCareCard;
