import 'bootstrap/dist/css/bootstrap.css';
import './carousel.scss';

import React, { useContext } from 'react';

import { Swipeable } from '../Swipeable/Swipeable';
import { carouselContext, CarouselContext } from './carouselContext';

export const CarouselDotGroup = () => {
    const { nSlides, activeIndex, gotoSlide, gotoNextSlide, gotoPreviousSlide } = useContext<CarouselContext>(carouselContext);
    const verifiedActiveIndex = Math.max(0, Math.min(nSlides - 1, activeIndex));
    return (
        <div data-testid='carousel-dot-group' className='w-100'>
            <div className='w-100 dot-group'>
                <Swipeable onSwipedLeft={gotoNextSlide} onSwipedRight={gotoPreviousSlide} swipeableClass='w-100 d-flex flex-row justify-content-center align-items-center'>
                    {new Array(nSlides).fill(0).map(
                        (_slide, index) => (
                            <button
                                data-testid='carousel-dot'
                                key={index}
                                onClick={() => gotoSlide && gotoSlide(index)}
                                className={`dot ${index == verifiedActiveIndex ? 'active' : ''}`}
                                aria-label={`goto slide number ${index + 1}`}
                            />
                        )
                    )}
                </Swipeable>
            </div>
        </div>
    );
};