import 'bootstrap/dist/css/bootstrap.css';
import './square-action-box.scss';

import React from 'react';

const SquareActionBox = ({
  tabIndex,
  iconPath,
  label,
  onClick,
  extraCssClass,
}: {
  tabIndex?:number,
  iconPath: string;
  label: string | undefined;
  description?: string;
  onClick?: () => void;
  extraCssClass?: string;
}) => {
  return (
    <button  className={`action-box ${extraCssClass || ''}`} onClick={onClick} tabIndex={tabIndex}>
      <img src={iconPath} alt='' aria-hidden={true} className='action-icon' />
      <div className='action-label'>{label}</div>
    </button>
  );
};

export default SquareActionBox;
