import 'bootstrap/dist/css/bootstrap.css';
import './carousel.scss';

import React, { useContext } from 'react';

import { carouselContext, CarouselContext } from './carouselContext';

export const CarouselCarets = () => {
    const { looping, nSlides, activeIndex, gotoPreviousSlide, gotoNextSlide } = useContext<CarouselContext>(carouselContext);
    const isLeftBoundary = !looping && nSlides && activeIndex == 0;
    const isRightBoundary = !looping && nSlides && activeIndex == nSlides - 1;
    return (<>
        {!isLeftBoundary &&
            <button data-testid='caret-left' className={`caret left ${isLeftBoundary ? 'disabled' : ''}`} aria-label='previous slide' onClick={gotoPreviousSlide}>
                <svg className="highlightable" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.255 19L9 11.405" />
                    <path d="M15.255 3.8093L9 11.4043" />
                </svg>
            </button>}
        {!isRightBoundary &&
            <button data-testid='caret-right' className={`caret right ${isRightBoundary ? 'disabled' : ''}`} aria-label='next slide' onClick={gotoNextSlide}>
                <svg className="highlightable" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.99891 3.81055L15.2539 11.4055" />
                    <path d="M8.99891 19.0012L15.2539 11.4062" />
                </svg>
            </button>}
    </>);
};