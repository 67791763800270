import 'bootstrap/dist/css/bootstrap.css';
import './carousel.scss';

import React, { useState } from 'react';

import { carouselContext } from './carouselContext';

export const CarouselProvider = ({ name, extraClass, children, looping }: {
    name?: string;
    extraClass?: string;
    looping?: boolean;
    children: React.ReactNode;
}) => {
    const [activeIndex, setActiveIndex] = useState<number>(0);

    return (
        <carouselContext.Provider value={{ nSlides: 0, name, looping, activeIndex, setActiveIndex }} >
            <div className={`mymh-carousel-container ${extraClass || ''}`}>
                {children}
            </div>
        </carouselContext.Provider>
    );
};

