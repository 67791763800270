import FHIR from 'fhirclient';
import Client from 'fhirclient/lib/Client';

import config from '../config/config';
import { KEY_language } from '../constants';
import { clearPatientSession, toastError } from './appService';
import { readFromStorage, removeFromStorage, writeToStorage } from './storageServies';
import { getLanguageFromUrl, LogVerbose } from './utilities';

export const smartLaunch = (renderApp: (fhirClient?: Client, lang?: string | null) => void) => {
    const queryParams = new URL(window.location.href).searchParams;

    const iss = queryParams.get('iss');
    const launch = queryParams.get('launch');
    const code = queryParams.get('code');
    const state = queryParams.get('state');
    if (iss && launch) {
        clearPatientSession();
        const lang = getLanguageFromUrl(window.location.href);
        if (lang) {
            writeToStorage(KEY_language, lang);
            LogVerbose(`Start SMART Launch with Lang = ${lang} ...`);
        }
        else {
            LogVerbose(`Start SMART Launch ...`);
        }
        FHIR.oauth2.authorize({
            clientId: config.smart.clientId,
            redirectUri: window.location.href.split('?')[0],
            scope: config.smart.scope
        });
    }
    else if (code && state) {
        const lang = readFromStorage<string>(KEY_language);
        if (lang) {
            removeFromStorage(KEY_language);
            LogVerbose(`Resume SMART Launch with Lang = ${lang} ...`);
        }
        else {
            LogVerbose(`Resume SMART Launch ...`);
        }

        FHIR.oauth2.ready()
            .then((client: Client) => {
                renderApp(client, lang);
            })
            .catch((e) => {
                console.error(`Failed to start a session due to: ${JSON.stringify(e)}`);
                toastError('Failed to sign in to MyMemorialHermann. Patient-only experience is disabled.');
                renderApp(undefined, lang);
            });
    }
    else {
        const lang = getLanguageFromUrl(window.location.href);
        renderApp(undefined, lang);
    }

};