import { EnumOption } from './types/interfaces';
import { Height } from './types/types';

export const KEY_DisableSplash: string = 'disableSplash';
export const KEY_homeImage = 'homeImage';
export const KEY_ViewFlag = 'viewFlag';

export const KEY_session = 'session';
export const KEY_sessionId = 'sessionId';
export const KEY_sessionUserId = 'sessionUserId';
export const KEY_sessionUser = 'sessionUser';
export const KEY_language = 'language';

export const KEY_patient = 'patient';
export const KEY_patient_name = 'patientName';

export const KEY_accessToken = 'accessToken';

export const KEY_scheduleInfo = 'scheduleInfo';

export const KEY_debug = 'dbg';

export const optionUnspecified: EnumOption = {
  value: '',
  display: 'Select',
};
export const IFrameDefaultHeight: Height = 800;
export const IFrameHeightUpdateIntervalTimeInMS: number = 500;
export const IFrameHeightUpdateCooldownTimeInMS: number = 2000;
// export const IFrameExtraHeight = 100;
export const IFrameExtraHeight = 250;
export const IFrameHeightPrecision = 250;

export const DefaultScreenSizes: { label: string; width: number }[] = [{ label: 'md', width: 1000 }, { label: 'lg', width: 1800 }, { label: 'xl', width: 2400 }]; 