import 'bootstrap/dist/css/bootstrap.css';
import './payBill.scss';

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import config from '../../config/config';
import { selectLanguage } from '../../reduxToolkit/features/languageSlice';
import {
    ContentDictionary, getContentDictionaryInCurrentLanguage
} from '../../services/contentService';
import {
    addQueryParameter, getUrlWithLanguage, isMobileApp, redirectToUrl
} from '../../services/utilities';
import Navigation from '../shared/Navigation/Navigation';
import MyMhSpinner from '../shared/Spinner/MyMhSpinner';

const PayAsGuest = () => {
  const [contentDictionary, setContentDictionary] =
    useState<ContentDictionary | null>(null);

  const language = useSelector(selectLanguage);

  const history = useHistory();

  // const onBackButtonClicked = () => {
  //   //history.goBack();
  //   history.push(config.urlConfig.home);
  // };

  useEffect(() => {
    const content = getContentDictionaryInCurrentLanguage('payBill');
    if (!content) {
      console.warn('Failed to load the content dictionary.');
    }
    setContentDictionary(content);
  }, [language]);

  const gotoMyChartPatAsGuest = () => {
    const url = addQueryParameter(config.urlConfig.myChartGuestBillPay, 'mobile', isMobileApp() ? '1' : '0');
    redirectToUrl(url);
  };

  // const gotoMhmgBillPay = () => {
  //   redirectToUrl(config.urlConfig.mhmgBillPay);
  // };


  // const gotoMhHospitalBillPay = () => {
  //   redirectToUrl(config.urlConfig.mhHospitalsBillPay);
  // };

  const mhmgUrl = getUrlWithLanguage(config.urlConfig.mhmgBillPay);
  const mhHospitalUrl = getUrlWithLanguage(config.urlConfig.mhHospitalsBillPay);

  if (!contentDictionary) return <MyMhSpinner />;

  return (
    <div className='w-100 h-100'>
      <h2 className='d-none'>Pay As Guest</h2>
      <Navigation
        navigationTitle={contentDictionary.payAsGuest}
        showBackButton={true}
        showHomeButton={true}
        onBackButtonClicked={() => history.push(config.urlConfig.home)}
      />
      <div className='w-100 padding-x d-flex flex-column justify-content-start align-items-start'>
        <div className='w-100 mb-32 header d-block billpay-header'>
          {contentDictionary.selectBillType}
        </div>
        <div className='w-100 mb-20 bill-type-card'>
          <div className='w-100 sub-header d-block mb-4px billpay-header'>
            {contentDictionary.mhmg}
          </div>
          <div className='w-100 sub-text'>
            <span>{contentDictionary.mhmgNotesPart1}</span>
            <a href={mhmgUrl}>
              {contentDictionary.billLinkText}
            </a>
            <span>{contentDictionary.makePayment}</span>
          </div>
        </div>
        <div className='w-100 mb-32 bill-type-card'>
          <div className='w-100 sub-header d-block mb-4px billpay-header'>
            {contentDictionary.mhHospitals}
          </div>
          <div className='w-100 sub-text'>
            <span>{contentDictionary.mhHospitalsNotesPart1}</span>
            <a href={mhHospitalUrl}>
              {contentDictionary.billLinkText}
            </a>
            <span>{contentDictionary.makePayment}</span>
          </div>
        </div>
        <div className='w-100 mb-20 text'>{contentDictionary.otherCases}</div>
        <button className='btn primary' onClick={gotoMyChartPatAsGuest}>
          {contentDictionary.payNowButton}
        </button>
      </div>
    </div>
  );
};

export default PayAsGuest;
