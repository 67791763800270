import { Patient } from '../types/interfaces';
import { NameType } from '../types/types';

export const getPatientName = ({ patient, type }: { patient?: Patient; type?: NameType }) => {
  if (!patient || !patient.name)
    return '';
  const names = patient.name;
  if (!names || names.length == 0)
    return '';
  const name = names[0];
  const givenNames = name.given;
  const lastName = name.family || '';

  if (type == 'Family')
    return lastName;

  const firstName = givenNames && givenNames.length > 0 ? givenNames[0] : '';
  if (type == 'Given')
    return firstName;

  if (!firstName)
    return lastName;
  else if (!lastName)
    return firstName;
  else
    return `${firstName} ${lastName}`;
};