import React from 'react';

import { LogVerbose } from '../../../services/utilities';

export interface CarouselContext {
    name?: string;
    looping?: boolean;
    nSlides: number,
    activeIndex: number;
    setActiveIndex: (activeIndex: number) => void;
    gotoSlide?: (activeIndex: number) => void;
    gotoPreviousSlide?: () => void;
    gotoNextSlide?: () => void;
}

export const carouselContext = React.createContext<CarouselContext>({
    activeIndex: 0,
    nSlides: 0,
    looping: false,
    setActiveIndex: (i: number) => {
        LogVerbose(`setting active index to ${i}`);
    },
    gotoSlide: (i: number) => { LogVerbose(`jumping to slide #${i + 1}`); }
});