import 'bootstrap/dist/css/bootstrap.css';
import './carousel.scss';

import React, { useContext, useEffect, useRef } from 'react';

import { isTouchCapable } from '../../../services/utilities';
import { CaretDisaplyOption } from '../../../types/types';
import { Swipeable } from '../Swipeable/Swipeable';
import { CarouselCarets } from './CarouselCarets';
import { carouselContext, CarouselContext } from './carouselContext';
import { CarouselDotGroup } from './CarouselDotGroup';

export const CarouselScroll = ({ tag, children, withCaret, withDotGroup, withLooping }: { tag?: string; withLooping: boolean; withDotGroup?: boolean; withCaret?: CaretDisaplyOption; children: React.ReactNode[] }) => {

    const { name, activeIndex, setActiveIndex, looping } = useContext<CarouselContext>(carouselContext);
    const isLooping = looping && withLooping;

    const carouselRef = useRef<HTMLDivElement>(null);

    const nSlides = children.length;
    const verifiedActiveIndex = Math.max(0, Math.min(nSlides - 1, activeIndex));

    useEffect(() => {
        if (carouselRef.current) {
            carouselRef.current.style.transform = `translateX(-${verifiedActiveIndex * 100}%)`;
        }
    }, [verifiedActiveIndex]);

    const getSlideIndex = (index: number) => {
        if (index < 0) index = isLooping && nSlides ? nSlides - 1 : 0;
        if (index > nSlides - 1) index = isLooping ? 0 : nSlides - 1;
        return index;
    };
    const gotoSlide = (index: number) => {
        setActiveIndex(getSlideIndex(index));
    };

    const gotoPreviousSlide = () => {
        gotoSlide(verifiedActiveIndex - 1);
    };

    const gotoNextSlide = () => {
        gotoSlide(verifiedActiveIndex + 1);
    };

    const getId = (index: number) => {
        let nameTag = name ? name : 'carousel';
        if (tag) {
            nameTag = `${nameTag}_${tag}`;
        }
        return `${nameTag}_${index}`;
    };

    if (nSlides == 0)
        return null;

    const showCaret = withCaret == 'Always' || (withCaret == 'NonTouch' && !isTouchCapable());

    return (
        <carouselContext.Provider value={{ name, looping: isLooping, nSlides, activeIndex: verifiedActiveIndex, setActiveIndex, gotoSlide, gotoPreviousSlide, gotoNextSlide }} >
            <div data-testid='carousel-stage' className='scroll-stage'>
                <Swipeable onSwipedLeft={gotoNextSlide} onSwipedRight={gotoPreviousSlide} swipeableClass='carousel-scroll'>
                    <div className='carousel-scroll-inner' ref={carouselRef}>
                        {children.map((slide, index) =>
                            // The tests rely on the active / no-active classes here. 
                            <div data-testid='carousel-slide' className={`carousel-scroll-item ${index == verifiedActiveIndex ? 'active' : 'non-active'}`} key={index} id={getId(index)}>
                                {slide}
                            </div>
                        )}
                    </div>
                </Swipeable>
                {showCaret && <CarouselCarets />}
            </div>
            {withDotGroup && <CarouselDotGroup />}
        </carouselContext.Provider >
    );
};

