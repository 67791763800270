import i18next from 'i18next';

import config from '../config/config';

// import { KEY_language } from '../constants';
// import {
//   getPersonalizedStorageKey,
//   readFromSessionStorage,
//   writeToSessionStorage,
// } from './sessionStorageServies';

export const getSupportedLanguages = () => {
  return config.languageConfig.languages;
};

export const isSupportedLangauge = (language: string | null | undefined) => {
  if (!language) return false;

  return config.languageConfig.languages.some((l) => l.name == language);
};

export const getCurrentLanguge = () => {
  const lan = i18next.resolvedLanguage;
  // console.debug(`Current Language = ${lan}`);
  return lan;

  // let language = readFromSessionStorage<string>(
  //   getPersonalizedStorageKey(KEY_language)
  // );
  // if (language) return language;

  // //if no preference
  // language = config.languageConfig.defaultLanguageName;
  // return language;
};

export const getDefaultLanguage = () => config.languageConfig.defaultLanguageName;

export const setPreferredLanguage = async (
  language: string,
  userId?: string,
) => {
  console.log(`Setting preferred language to ${language}`);
  if (!isSupportedLangauge(language)) {
    console.error(`Language ${language} is not supported.`);
    return getCurrentLanguge();
  }

  await i18next.changeLanguage(language);
  console.debug(`Preferred language has been switched to ${i18next.resolvedLanguage}`);
  // writeToSessionStorage(getPersonalizedStorageKey(KEY_language), language);

  if (userId) {
    //If logged in, call api to update user preference
  }

  return language;
};

export const initializePreferredLanguage = async (userId?: string) => {
  //to be called after log in
  if (userId) {
    //call api to get user preference
    //then set it in the session storage
  }
};
