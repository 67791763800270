import React from 'react';
import { ScheduleInfo } from '../../types/interfaces';
import { formatPhoneNumber } from '../../services/utilities';


interface Props {
    visitInfo: ScheduleInfo;
}

const ScheduledVisit = ({ visitInfo }: Props): JSX.Element => {
    const {
        appointmentType,
        providerName,
        timeAsUnixEpochNumber,
        duration,
        locationName,
        address,
        address2,
        city,
        state,
        zipCode,
        phoneNumber
    } = visitInfo;

    let completeAddress = address;
    if (completeAddress && address2) {
        completeAddress = `${completeAddress}, ${address2}`;
    }

    const getDateMonth = () => {
        return timeAsUnixEpochNumber ? new Date(timeAsUnixEpochNumber * 1000).toDateString().split(' ')[1].toUpperCase() : '';
    };

    const getDateDay = () => {
        return timeAsUnixEpochNumber ? new Date(timeAsUnixEpochNumber * 1000).toDateString().split(' ')[2].replace(/(^|-)0+/g, "$1") : '';
    };

    const getDateYear = () => {
        return timeAsUnixEpochNumber ? new Date(timeAsUnixEpochNumber * 1000).toDateString().split(' ')[3] : '';
    };

    const getTime = () => {
        return timeAsUnixEpochNumber ? new Date(timeAsUnixEpochNumber * 1000).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
        }) : '';
    };

    return (
        <>
            <div className='appointment'>
                <div className='date'>
                    <p className='month'>{getDateMonth()}</p>
                    <p className='day'>{getDateDay()}</p>
                    <p className='year'>{getDateYear()}</p>
                </div>
                <div className='details'>
                    <p className='appointment-name'>{appointmentType}</p>
                    {providerName && <p className='app-info'>{providerName}</p>}
                    <p className='app-info'>{locationName}</p>
                    {completeAddress && city && state && zipCode &&
                        <p className='app-info'>{`${completeAddress}, ${city}, ${state} ${zipCode}`}</p>}
                    {phoneNumber && <p className='app-info'>{formatPhoneNumber(phoneNumber, 'A-B-C')}</p>}
                    <p className='app-info'>Starts at {getTime()} ({duration} minutes)</p>
                </div>
            </div>
        </>
    );
};

export default ScheduledVisit;