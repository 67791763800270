import 'bootstrap/dist/css/bootstrap.css';
import './scheduling.scss';

import React, { useEffect, useState } from 'react';
import { getSchedules } from '../../services/userService';
import { ScheduleInfo } from '../../types/interfaces';
import MyMhSpinner from '../shared/Spinner/MyMhSpinner';
import ScheduledVisit from './ScheduledVisit';

const initialCountToDisplay = 10;

const ScheduledVisits = () => {
  const [callingApi, setCallingApi] = useState<boolean>(false);
  const [showMore, setShowMore] = useState<boolean>(false);
  const [visits, setVisits] = useState<ScheduleInfo[]>([]);
  const [visitsToDisplay, setVisitsToDisplay] = useState<ScheduleInfo[]>([]);

  const extractContextTokenFromUrl = () => {
    const queryParams = new URL(window.location.href).searchParams;
    const contextToken = queryParams.get('context') || queryParams.get('Context');
    return contextToken;
  };

  useEffect(() => {
    const token = extractContextTokenFromUrl();
    setCallingApi(true);
    getSchedules({ epicContextToken: token }).then((result) => {
      if (result.ok) {
        setVisits(result.data!.sort((a, b) => a.timeAsUnixEpochNumber > b.timeAsUnixEpochNumber ? 1 : -1));
        setVisitsToDisplay(result.data!
          .sort((a, b) => a.timeAsUnixEpochNumber > b.timeAsUnixEpochNumber ? 1 : -1)
          .slice(0, initialCountToDisplay));
        if (result.data!.length > 10)
          setShowMore(true);
      }
      else {
        const msg = result.message || 'Failed to retrieve the scheduled visits from the API.';
        console.error(msg);
        //toastError('Failed to retrieve the scheduled visits from the API.');
      }
    }).catch((e) => {
      console.error(e);
      //toastError('Failed to retrieve the scheduled visits from the API.');
    })
      .finally(() => {
        setCallingApi(false);
      }
      );
  }, []);

  if (callingApi)
    return <div className='no-top-banner'><MyMhSpinner /></div>;

  const loadMore = () => {
    setVisitsToDisplay([...visitsToDisplay, ...visits.slice(visitsToDisplay.length, visitsToDisplay.length + initialCountToDisplay)]);
    if (visitsToDisplay.length + initialCountToDisplay >= visits.length)
      setShowMore(false);
  };

  return (
    <div className='w-100 h-100 visits no-top-banner'>
      <h2 className='d-none'>Scheduled Visits</h2>
      <div className='visits-header'>
        <span>TMC Clinic Appointments</span>
        <p className='visits-disclaimer'>
          Upcoming appointments for the cancer, wound care, digestive disease, transplant and advance heart failure centers
          at Memorial Hermann - Texas Medical Center locations can be viewed below. If you need to reschedule or
          cancel your appointment, or you do not see any appointments you previously scheduled,
          please contact your provider’s office directly.
        </p>
      </div>
      {visitsToDisplay && visitsToDisplay.length == 0 ?
        <div className='msg-container'>
          <div className='msg'>There are no upcoming visits to display.</div>
        </div>
        :
        <>
          <div>
            {visitsToDisplay.map((visit) => (
              <ScheduledVisit visitInfo={visit} key={visit.id} />
            ))}
          </div>
          {showMore &&
            <div className='more-link'>
              <a onClick={loadMore}>Load more future visits</a>
            </div>
          }
        </>
      }
    </div>
  );
};

export default ScheduledVisits;