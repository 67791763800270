import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './functionIsComing.scss';


const FunctionIsComing = () => {
  return <div className='with-ecl-banner padding-x'>
    <h2 className='text-center'>The functionality to view study images through EpicCare Link is coming soon! Thank you for your patience.</h2>
  </div>;
};

export default FunctionIsComing;