import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { User } from '../../types/user';
import { State, UserState } from '../state';

const initialState: UserState = {
  user: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    updateUser: (state: UserState, action: PayloadAction<User | null>) => {
      //return { ...state, user: action.payload };

      //@@@ Redux Toolkit allows us to write "mutating" logic in reducers. It
      //@@@ doesn't actually mutate the state because it uses the immer library,
      //@@@ which detects changes to a "draft state" and produces a brand new
      //@@@ immutable state based off those changes
      state.user = action.payload; //@@@
    },
  },
});

export const { updateUser } = userSlice.actions;
export const selectUser = (state: State): User | null => state.user.user;
export default userSlice.reducer;
