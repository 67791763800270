import 'bootstrap/dist/css/bootstrap.css';
import './iFrameScreen.scss';

import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import useStateRef from 'react-usestateref';

import { IFrameDefaultHeight, IFrameHeightPrecision } from '../../../constants';
import {
  getUrlWithLanguage, getUrlWithoutqueryString, isMobileApp, LogVerbose
} from '../../../services/utilities';
import { Height, HeightAdjustmentStage } from '../../../types/types';
import Navigation from '../Navigation/Navigation';

// import config from '../../../config/config';
// import { useHistory } from 'react-router';

type IFrameMessageType = 'PAGE_LOADED' | 'SET_HEIGHT' | 'RECAL_HEIGHT';

interface HeightAdjustmentStatus {
  height: Height;
  stage: HeightAdjustmentStage;
  scrollY: number;
  frameScrollY?: number;
}

const scrollYTo = (scrollY: number) => {
  if (scrollY != window.scrollY) { //There are likely two scrollbars immediately after the reset. In such case the previously recorded scroolY might not apply
    LogVerbose(`M: scrolling from ${window.scrollY} to ${scrollY} after iFrame is loaded`, 'color: darkcyan');
    window.scrollTo(0, scrollY);
  }
};


const IFramedScreen = ({
  url,
  pageName,
  showBackButton,
  onBackButtonClicked,
  showHomeButton,
  extraClass,
  height
}: {
  url: string;
  pageName: string;
  showBackButton?: boolean;
  onBackButtonClicked?: () => void;
  showHomeButton?: boolean;
  extraClass?: string;
  height?: Height;
}) => {
  const iframeId = 'myMh-iframe';
  const initialHeight = height || IFrameDefaultHeight;

  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  const [heightAdjustmentStatus, setHeightAdjustmentStatus, heightAdjustmentStatusRef] = useStateRef<HeightAdjustmentStatus>({ height: initialHeight, stage: 'INITIAL', scrollY: 0, frameScrollY: 0 });

  const [frameSrc, setFrameSrc] = useState<string | undefined>(undefined);
  const [frameLocation, setFrameLocation, frameLocationRef] = useStateRef<string | undefined>(undefined);

  const isWebview = useMemo(isMobileApp, []);

  const reCalIframeContnentHeight = () => {
    if (iframeRef.current?.contentWindow) {
      iframeRef.current?.contentWindow.postMessage({ eventName: 'Recalculate_Height', payload: { isWebview: isWebview, scrollY: heightAdjustmentStatusRef.current.frameScrollY } }, '*');
    }
  };

  const resetHeight = (messageType: IFrameMessageType, targetScrollY: number, frameScrollY?: number) => {
    // const scrolly = targetScrollY || heightAdjustmentStatusRef.current.scrollY;
    const scrollY = targetScrollY;
    LogVerbose(`M: ${messageType} - [State-Update] Reset iframe height, scollY ${scrollY}`, 'color: red');
    LogVerbose(`M: ${messageType} - Logged scroll ${scrollY}, Current height ${heightAdjustmentStatusRef.current.height}`, 'color:cyan');
    setHeightAdjustmentStatus({ height: initialHeight, stage: 'RESET', scrollY: scrollY, frameScrollY: frameScrollY });
  };

  const getNewHeight = (reportedHeight: number) => {
    return Math.max(initialHeight, reportedHeight + IFrameHeightPrecision);
  };

  const setHeightMessageHandler = async (reportedHeight: number) => {

    const { height, stage, scrollY } = heightAdjustmentStatusRef.current;
    //LogVerbose(`M: SET_HEIGHT- Current height: ${height}, New height = ${reportedHeight}`, 'color: blue');

    if (stage == 'RESET') {
      LogVerbose(`M: SET_HEIGHT- The check-height task is blocked as we are waiting for the recalculated height after the RESET.`, 'color:pink; font-weight:bold;');
    }
    else {
      if (height == initialHeight) {
        if (reportedHeight > initialHeight) {
          LogVerbose(`M: SET_HEIGHT- [State-Update] The current iframe height ${height} is same as the initial height and the new height ${reportedHeight} is larger. Use the new height. ScollY ${scrollY}`, 'color:#8B8000;font-weight:bold;');
          const newHeight = getNewHeight(reportedHeight);
          setHeightAdjustmentStatus({ height: newHeight, stage: 'UPDATE', scrollY: window.scrollY });
        }
        else {
          LogVerbose(`M: SET_HEIGHT- The current iframe height ${height} is same as the initial height but the the new height ${reportedHeight} is small. Keep the current height. ScollY ${scrollY}`);
        }
      }
      else if (reportedHeight > height) {
        LogVerbose(`M: SET_HEIGHT- [State-Update] The reported height ${reportedHeight} is larger than the current iframe height ${height}. Use new height. ScollY ${scrollY}`, 'color:#8B8000;font-weight:bold;');
        const newHeight = getNewHeight(reportedHeight);
        setHeightAdjustmentStatus({ height: newHeight, stage: 'UPDATE', scrollY: window.scrollY });
      }
      else if (reportedHeight < height - IFrameHeightPrecision) {
        LogVerbose(`M: SET_HEIGHT- [State-Update] The reported height ${reportedHeight} is smaller than the current iframe height ${height}. Use new height. ScollY ${scrollY}`, 'color:#8B8000;font-weight:bold;');
        const newHeight = getNewHeight(reportedHeight);
        setHeightAdjustmentStatus({ height: newHeight, stage: 'UPDATE', scrollY: window.scrollY });
      }
      // else if(reportedHeight==height) {
      //   LogVerbose(`M: SET_HEIGHT- [State-Update] The reported height ${reportedHeight} is exactly same as the current iframe height ${height}. Reset the height. ScollY ${scrollY}`, 'color:#8B8000;font-weight:bold;');
      //   const targetScrollY = window.scrollY == 0 ? scrollY : window.scrollY;
      //   resetHeight(targetScrollY);
      // }
      // else {
      //   LogVerbose(`M: SET_HEIGHT- The reported height ${reportedHeight} is roughfly same as the current iframe height ${height}. Keep the current height. ScollY ${scrollY}`);
      // }
      else if (height == reportedHeight) {
        LogVerbose(`M: SET_HEIGHT- [State-Update] The reported height ${reportedHeight} is exactly same as the current iframe height ${height}. ScollY ${scrollY}`, 'color:#8B8000;font-weight:bold;');
        // const targetScrollY = window.scrollY == 0 ? scrollY : window.scrollY;
        resetHeight('SET_HEIGHT', window.scrollY);
      }
      else {
        LogVerbose(`M: SET_HEIGHT- The current iframe height ${height} is close to the new height ${reportedHeight}. Keep the current height. ScollY ${scrollY}`);
      }
    }
  };

  const verifyHeightMessageHandler = async (reportedHeight: number) => {
    const { height, stage, scrollY } = heightAdjustmentStatusRef.current;
    LogVerbose(`M: RECAL_HEIGHT - The height has been recalculted - current height =  ${height},  new height ${reportedHeight}.`, 'color:darkred');
    if (reportedHeight == height) {
      LogVerbose(`M: RECAL_HEIGHT - The new height is same as the current iframe height. Keep the current height. ScollY ${scrollY}`);
      if (stage == 'RESET') {
        LogVerbose('M: RECAL_HEIGHT - [State-Update] update the stage from RESET to IGNORE', 'color:#8B8000;font-weight:bold;');
        setHeightAdjustmentStatus({ height: height, stage: 'IGNORE', scrollY: scrollY });
      }
      // if (scrollY != 0) {
      //   scrollYTo(scrollY);
      // }
    }
    else {
      if (height == initialHeight) {
        if (reportedHeight > initialHeight) {
          LogVerbose(`M: RECAL_HEIGHT- [State-Update] The current iframe height ${height} is same as the initial height and the new height ${reportedHeight} is larger. Use the new height. ScollY ${scrollY}`, 'color:#8B8000;font-weight:bold;');
          const newHeight = getNewHeight(reportedHeight);
          setHeightAdjustmentStatus({ height: newHeight, stage: 'UPDATE', scrollY: scrollY });
        }
        else {
          LogVerbose(`M: RECAL_HEIGHT- The current iframe height ${height} is same as the initial height but the the new height ${reportedHeight} is small. Keep the current height. ScollY ${scrollY}`);
          if (stage == 'RESET') {
            LogVerbose('M: RECAL_HEIGHT - [State-Update] update the stage from RESET to IGNORE', 'color:#8B8000;font-weight:bold;');
            setHeightAdjustmentStatus({ height: height, stage: 'IGNORE', scrollY: scrollY });
          }
        }
      }
      else {
        if (reportedHeight > height) {
          LogVerbose(`M: RECAL_HEIGHT- [State-Update] The reported height ${reportedHeight} is larger than the current iframe height ${height}. Use new height. ScollY ${scrollY}`, 'color:#8B8000;font-weight:bold;');
          const newHeight = getNewHeight(reportedHeight);
          setHeightAdjustmentStatus({ height: newHeight, stage: 'UPDATE', scrollY: scrollY });
        }
        else if (reportedHeight < height - IFrameHeightPrecision) {
          LogVerbose(`M: RECAL_HEIGHT- [State-Update] The reported height ${reportedHeight} is smaller than the current iframe height ${height}. Use new height. ScollY ${scrollY}`, 'color:#8B8000;font-weight:bold;');
          const newHeight = getNewHeight(reportedHeight);
          setHeightAdjustmentStatus({ height: newHeight, stage: 'UPDATE', scrollY: scrollY });
        }
        else {
          LogVerbose(`M: RECAL_HEIGHT- The current iframe height ${height} is close to the new height ${reportedHeight}. Keep the current height. ScollY ${scrollY}`);
        }
      }
    }
  };

  const pageLoadMessageHandler = (newFrameLocation?: string) => {
    if (!newFrameLocation) {
      return;
    }

    if (!frameLocationRef.current) {
      LogVerbose(`M: PAGE_LOADED - [State-Update] update the FrameLocation from ${frameLocationRef.current} to ${newFrameLocation}`, 'color:#8B8000;font-weight:bold;');
      setFrameLocation(newFrameLocation);
      return;
    }

    if (newFrameLocation == frameLocationRef.current) {
      LogVerbose('M: PAGE_LOADED - iFrame location remains unchanged. This event is ignored.', 'color: orange');
      return;
    }

    const currentLoc = getUrlWithoutqueryString(frameLocationRef.current);
    const newLoc = getUrlWithoutqueryString(newFrameLocation);
    if (currentLoc != newLoc) {
      LogVerbose(`M: PAGE_LOADED - [State-Update] iFrame has been loaded/reloaded at ${newLoc} (Previous location: ${currentLoc}). Initializing iframe height ...`, 'color:red;font-weight:bold');
      setFrameLocation(newFrameLocation);
      resetHeight('PAGE_LOADED', 0, 0);
      scrollYTo(0);
    }
    else {
      LogVerbose('M: PAGE_LOADED - iFrame location remains unchanged. This event is ignored.', 'color: orange');
    }
    // if (newFrameLocation.toString() != frameLocationRef.current.toString()) {
    //   LogVerbose(`M: [State-Update] update the FrameLocation from ${frameLocationRef.current} to ${newFrameLocation}`, 'color:#8B8000;font-weight:bold;');
    //   setFrameLocation(newFrameLocation);
    // }
  };

  const handleReceiveMessage = (event?: { data?: { eventName?: IFrameMessageType; payload?: { height?: Height, msgId?: number, href?: string; }; }; }) => {
    const eventName = event?.data?.eventName;
    const payload = event?.data?.payload;

    if (eventName === 'PAGE_LOADED') {
      const newLocation = payload?.href;
      LogVerbose(`M: Inside handle Receive PAGE_LOADED with location: ${newLocation}`, 'color: purple');
      pageLoadMessageHandler(newLocation);
      return;
    }
    else if (eventName === 'SET_HEIGHT') {
      const reportedHeight = payload?.height;
      LogVerbose(`M: Inside handle Receive Message SET_HEIGHT with height: ${reportedHeight}`, 'color: blue');
      if (!reportedHeight) {
        LogVerbose(`M: SET_HEIGHT - No new height`, 'color: grey');
        return;
      }
      setHeightMessageHandler(reportedHeight);
    }
    else if (eventName === 'RECAL_HEIGHT') {
      const reportedHeight = payload?.height;
      LogVerbose(`M: Inside handle Receive Message RECAL_HEIGHT with height: ${reportedHeight}`, 'color: darkred');

      if (!reportedHeight) {
        LogVerbose(`M: RECAL_HEIGHT - No new height`, 'color: grey');
        return;
      }
      verifyHeightMessageHandler(reportedHeight);
    }

  };


  // const handleIFrameLoaded = () => {
  //   const { stage, scrollY } = heightAdjustmentStatusRef.current;
  //   if (stage != 'RESET' && scrollY != 0) { //There are likely two scrollbars immediately after the reset. In such case the previously recorded scroolY might not apply
  //     LogVerbose('iFrame is loaded. Scrolling...');
  //     scrollYTo(scrollY);
  //   }
  // };

  useEffect(() => {
    LogVerbose(`iFraming url: ${url}`);
    const urlWithLang = getUrlWithLanguage(url);
    LogVerbose(`iFrame src = ${urlWithLang}`);
    LogVerbose(`M: [State-Update] update the FrameSrc and FrameLocation from ${frameSrc} to ${urlWithLang}`, 'color:#8B8000;font-weight:bold;');
    setFrameSrc(urlWithLang);
    setFrameLocation(urlWithLang);

    window.addEventListener('message', handleReceiveMessage);
  }, []);

  // useEffect(() => {
  //   LogVerbose('register fresh event listener');
  //   window.addEventListener('message', handleReceiveMessage);
  //   return window.removeEventListener('message', handleReceiveMessage);
  // }, [frameLocation, heightAdjustmentStatus]);

  useLayoutEffect(() => {
    //Here we use useLayoutEffect to make sure this runs only after the rendering is completed.
    if (frameSrc) {
      const { stage, scrollY } = heightAdjustmentStatus;
      // if (stage == 'RESET' || stage == 'INITIAL') {
      //   LogVerbose('M: Request to the iframe to recalculate its height.', 'color:#ff6600');
      //   reCalIframeContnentHeight();
      // }

      reCalIframeContnentHeight();
      if (stage != 'RESET' && scrollY != 0) { //There are likely two scrollbars immediately after the reset. In such case the previously recorded scroolY might not apply
        LogVerbose('iFrame is loaded. Check scrolling...');
        scrollYTo(scrollY);
      }
    }

    // if (frameSrc) {
    //   LogVerbose('M: Request to the iframe to recalculate its height.', 'color:#ff6600');
    //   reCalIframeContnentHeight();
    // }
  }, [heightAdjustmentStatus, frameSrc]);

  if (!frameSrc) {
    return null;
  }
  // const history = useHistory();

  LogVerbose(`M: Rendering with iFrame Height = ${heightAdjustmentStatus.height} ScrollY = ${heightAdjustmentStatus.scrollY} Stage = ${heightAdjustmentStatus.stage} FrameLocation = ${frameLocation}`, 'font-weight:bold;color:green');

  return (
    <div className={`iframed-screen ${extraClass || ''}`}>
      <h2 className='d-none'>{pageName}</h2>
      <Navigation
        navigationTitle={pageName}
        showBackButton={showBackButton || false}
        showHomeButton={showHomeButton || false}
        onBackButtonClicked={onBackButtonClicked}
      />
      <iframe
        id={iframeId} title={pageName} ref={iframeRef} src={frameSrc} allow='fullscreen' className='iframe' height={heightAdjustmentStatus.height}
      // onLoad={handleIFrameLoaded}
      ></iframe>
    </div>
  );
};

export default IFramedScreen;
