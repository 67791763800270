import './App.scss';

import Client from 'fhirclient/lib/Client';
import React, { useEffect, useState } from 'react';
import { Provider, useDispatch } from 'react-redux';
import { Route, Switch as Routes } from 'react-router-dom';

import FunctionIsComing from './components/ErrorPage/FunctionIsComing/FunctionIsComing';
// import FunctionIsComing from './components/ErrorPage/FunctionIsComing/FunctionIsComing';
import GetCareNow from './components/GetCareNow/GetCareNow';
import Home from './components/Home/Home';
import PageNotFound from './components/PageNotFound/PageNotFound';
import PayAsGuest from './components/PayBill/PayAsGuest';
import ScheduledVisits from './components/Scheduling/ScheduledVisits';
import Scheduling from './components/Scheduling/Scheduling';
import SchedulingGuide from './components/Scheduling/SchedulingGuide';
import UrgentCare from './components/UrgentCare/UrgentCare';
import VirtualUrgentCare from './components/VirtualUrgentCare/VirtualUrgentCare';
import config from './config/config';
import { KEY_debug } from './constants';
import { updateLanguage } from './reduxToolkit/features/languageSlice';
import store from './reduxToolkit/store';
import { toastError } from './services/appService';
import i18n, { initI18n } from './services/i18n';
import { getCurrentLanguge, setPreferredLanguage } from './services/languageService';
import { readFromStorage } from './services/storageServies';
import { addQueryParameter, isMobileApp, LogVerbose, redirectToUrl } from './services/utilities';

const App = ({ fhirClient, lang }: { fhirClient?: Client; lang?: string | null; }) => {
  const [loaded, setLoaded] = useState<boolean>(i18n.isInitialized);
  const dispatch = useDispatch();

  useEffect(() => {
    //Note Suspense somehow does not work as expected in FireFox. We have to make sure i18next is initialized before rendering any page
    // const isI18nReady = i18next.isInitialized;
    // LogVerbose('Loading app ...');
    // LogVerbose('i18Next: initialized = ', isI18nReady);
    if (lang) {
      LogVerbose('Detected language request:', lang);
    }

    if (!i18n.isInitialized) {
      setLoaded(false);
      const fallbackLang = lang || config.languageConfig.defaultLanguageName;
      initI18n(fallbackLang, false)
        .then(() => {
          dispatch(updateLanguage(getCurrentLanguge()));
        })
        .catch((err) => {
          console.error('Failed to initialize i18next: ', err);
          toastError('Something went wrong. Please try again later.');
        })
        .finally(() => setLoaded(true));
    }
    else if (lang != undefined && lang != null && lang != getCurrentLanguge()) {
      setLoaded(false);
      setPreferredLanguage(lang).then((language) => {
        LogVerbose(`Switched i18n to: ${language} `);
        dispatch(updateLanguage(language));
      }
      ).catch((e) => console.error(e)).finally(() => setLoaded(true));
    }

  }, [lang]);

  if (!loaded) {
    LogVerbose('Loading translations ...');
    // return (
    //   <div className='w-100 h-100 position-relative'>
    //     <MyMhSpinner />
    //   </div>
    // );
    return null;
  }

  const isBrowserView = !isMobileApp();

  return (
    <div className={`mymh-app ${!isBrowserView ? 'mobile-app' : ''}`}>
      <Provider store={store}>
        <Routes>
          <Route path={config.urlConfig.root} exact component={Home} />
          <Route
            path={config.urlConfig.home}
            exact
            render={() => (
              <Home fhirClient={fhirClient} />
            )}
          />
          <Route
            path={config.urlConfig.patientHome}
            exact
            render={() => (
              <Home fhirClient={fhirClient} />
            )}
          />
          <Route
            path={config.urlConfig.gotoMychartLogin}
            exact
            render={() => {
              if (isBrowserView) {
                if (readFromStorage(KEY_debug) == 'true') {
                  toastError(`Current URL = ${window.location.href}; Redirecting to ${config.urlConfig.myChartLoginUrl}`);
                  setTimeout(() => redirectToUrl(config.urlConfig.myChartLoginUrl, 'replace'), 10000);
                }
                else
                  redirectToUrl(config.urlConfig.myChartLoginUrl, 'replace');
              }
              else {
                if (readFromStorage(KEY_debug) == 'true') {
                  toastError(`Current URL = ${window.location.href}; Redirecting to ${config.urlConfig.myChartLoginDeepLink}`);
                  setTimeout(() => redirectToUrl(config.urlConfig.myChartLoginDeepLink), 10000);
                }
                else
                  redirectToUrl(config.urlConfig.myChartLoginDeepLink);
              }
              return <Home fhirClient={fhirClient} />;
            }}
          />
          <Route
            path={config.urlConfig.gotoMychartHome}
            exact
            render={() => {
              if (isBrowserView) {
                if (readFromStorage(KEY_debug) == 'true') {
                  toastError(`Current URL = ${window.location.href}; Redirecting to ${config.urlConfig.myChartHomeUrl}`);
                  setTimeout(() => redirectToUrl(config.urlConfig.myChartHomeUrl, 'replace'), 10000);
                }
                else
                  redirectToUrl(config.urlConfig.myChartHomeUrl, 'replace');
              }
              else {
                if (readFromStorage(KEY_debug) == 'true') {
                  toastError(`Current URL = ${window.location.href}; Redirecting to ${config.urlConfig.myChartHomeDeepLink}`);
                  setTimeout(() => redirectToUrl(config.urlConfig.myChartHomeDeepLink), 10000);
                }
                else
                  redirectToUrl(config.urlConfig.myChartHomeDeepLink);
              }
              return <Home fhirClient={fhirClient} />;
            }}
          />
          <Route
            path={config.urlConfig.gotoTestResult}
            exact
            render={() => {
              if (isBrowserView)
                redirectToUrl(config.urlConfig.myChartTestResults, 'replace');
              else redirectToUrl(config.urlConfig.myChartTestResultsDeepLink);
              return <Home fhirClient={fhirClient} />;
            }}
          />
          <Route
            exact
            path={config.urlConfig.payAsGuest}
            component={PayAsGuest}
          />
          <Route
            path={config.urlConfig.payBill}
            exact
            render={() => {
              if (isBrowserView)
                redirectToUrl(config.urlConfig.myChartPayBill, 'replace');
              else redirectToUrl(config.urlConfig.myChartPayBillDeeplink);
              return <Home fhirClient={fhirClient} />;
            }}
          />

          <Route
            path={config.urlConfig.guestCostEstimate}
            render={() => {
              const url = addQueryParameter(config.urlConfig.myChartGuestCostEstimate, 'mobile', isBrowserView ? '0' : '1');
              redirectToUrl(url, 'replace');
              return <Home fhirClient={fhirClient} />;
            }}
          />
          <Route
            path={config.urlConfig.costEstimate}
            exact
            render={() => {
              if (isBrowserView)
                redirectToUrl(config.urlConfig.myChartCostEstimate, 'replace');
              else redirectToUrl(config.urlConfig.myChartCostEstimateDeeplink);
              return <Home fhirClient={fhirClient} />;
            }}
          />

          <Route path={config.urlConfig.getCareNow} component={GetCareNow} />
          <Route path={config.urlConfig.urgentCare} component={UrgentCare} />
          <Route
            path={config.urlConfig.virtualUrgentCare}
            component={VirtualUrgentCare}
          />
          <Route
            path={config.urlConfig.scheduling}
            render={() => <Scheduling type='Scheduling' />}
          />
          <Route
            path={config.urlConfig.quickcareScheduling}
            render={() => <Scheduling type='UrgentScheduling' />}
          />
          <Route
            path={config.urlConfig.schedulingGuide}
            exact
            render={() => <SchedulingGuide category='GeneralScheduling' />}
          />
          <Route
            exact
            path={config.urlConfig.imageScheduling}
            render={() => <SchedulingGuide category='ImagingScheduling' />}
          />
          {/* <Route
            exact
            path={config.urlConfig.screeningScheduling}
            render={() => <SchedulingGuide category='ScreeningScheduling' />}
          /> */}
          {/* <Route
            exact
            path={config.urlConfig.therapyScheduling}
            render={() => <SchedulingGuide category='TherapyScheduling' />}
          /> */}
          <Route
            exact
            path={config.urlConfig.programsScheduling}
            render={() => {
              redirectToUrl(config.urlConfig.mhProgramsAndClasses, 'replace');
              return null;
            }}
          />
          <Route
            path={config.urlConfig.tsBariatrics}
            exact
            render={() => <SchedulingGuide category='TicketScheduling' service='Bariatrics' />}
          />
          <Route
            path={config.urlConfig.tsCardiology}
            exact
            render={() => <SchedulingGuide category='TicketScheduling' service='Cardiology' />}
          />
          <Route
            path={config.urlConfig.tsEndocrinology}
            exact
            render={() => <SchedulingGuide category='TicketScheduling' service='Endocrinology' />}
          />
          <Route
            path={config.urlConfig.tsGastroenterology}
            exact
            render={() => <SchedulingGuide category='TicketScheduling' service='Gastroenterology' />}
          />
          <Route
            path={config.urlConfig.tsGeneralSurgery}
            exact
            render={() => <SchedulingGuide category='TicketScheduling' service='GeneralSurgery' />}
          />
          <Route
            path={config.urlConfig.tsNeurology}
            exact
            render={() => <SchedulingGuide category='TicketScheduling' service='Neurology' />}
          />
          <Route
            path={config.urlConfig.tsPediatricsWellChild}
            exact
            render={() => <SchedulingGuide category='TicketScheduling' service='PediatricsWellChild' />}
          />
          <Route
            path={config.urlConfig.tsPediatricsBHV}
            exact
            render={() => <SchedulingGuide category='TicketScheduling' service='PediatricsBHV' />}
          />
          <Route
            path={config.urlConfig.tsUrology}
            exact
            render={() => <SchedulingGuide category='TicketScheduling' service='Urology' />}
          />
          <Route
            path={config.urlConfig.tsFamilyMedicine}
            exact
            render={() => <SchedulingGuide category='TicketScheduling' service='FamilyMedicine' />}
          />
          <Route
            path={config.urlConfig.tsOBGYN}
            exact
            render={() => <SchedulingGuide category='TicketScheduling' service='OBGYN' />}
          />
          <Route
            path={config.urlConfig.tsAllergyImmunology}
            exact
            render={() => <SchedulingGuide category='TicketScheduling' service='AllergyImmunology' />}
          />
          <Route
            path={config.urlConfig.tsInfectiousDisease}
            exact
            render={() => <SchedulingGuide category='TicketScheduling' service='InfectiousDisease' />}
          />
          <Route
            path={config.urlConfig.tsRheumatology}
            exact
            render={() => <SchedulingGuide category='TicketScheduling' service='Rheumatology' />}
          />
          <Route
            path={config.urlConfig.functionIsComing}
            exact
            render={() => <FunctionIsComing />}
          />
          <Route
            path={config.urlConfig.visits}
            exact
            render={() => <ScheduledVisits />}
          />
          <Route
            exact
            path={config.urlConfig.supportRoute}
            render={() => {
              redirectToUrl(config.urlConfig.supportUrl);
              return null;
            }}
          />
          <Route
            exact
            path={config.urlConfig.surveyRoute}
            render={() => {
              redirectToUrl(config.urlConfig.surveyLink);
              return null;
            }}
          />
          {/* <Route
            exact
            path={config.urlConfig.supportRoute}
            render={() => <a href={config.urlConfig.supportUrl} target='_self'>Click here to go to {config.urlConfig.supportUrl}</a>}
          />
          <Route
            exact
            path={config.urlConfig.surveyRoute}
            render={() => <a href={config.urlConfig.surveyLink} target='_self'>Click here to go to {config.urlConfig.surveyLink}</a>}
          /> */}
          <Route path='*' component={PageNotFound} />
        </Routes>
      </Provider>
    </div>
  );
};


export default App;
