import { KEY_accessToken } from '../constants';
import { Result } from '../types/result';
// import { writeToStorage } from './storageServies';
import { FailedResult, GeneralResult, LogVerbose } from './utilities';

export interface HeaderItem {
  key: string;
  value: string;
}

export interface ApiOptions {
  url: string;
  method?: 'GET' | 'PUT' | 'POST' | 'DELETE';
  body?: object;
  contentType?: string;
  token?: string;
  extraHeaders?: HeaderItem[];
  asUrlSearchParams?: boolean;
  description?: string;
}

export interface OktaApiTokenResponse {
  token_type: string;
  expires_in: number;
  access_token: string;
  scope?: string;
}

export interface ApiErrorMessage {
  errorMessage?: string;
}

// const KEY_clientToken = 'clientToken';

// export const getClientToken = async () => {
//   return null;

//   const headers = new Headers();
//   headers.append('Content-Type', 'application/x-www-form-urlencoded');
//   headers.append('Authorization', config.tokenEndpoint?.auth);

//   const urlencoded = new URLSearchParams();
//   config.tokenEndpoint.payload.map((kvp) =>
//     urlencoded.append(kvp.key, kvp.value),
//   );

//   const requestOptions = {
//     method: 'POST',
//     headers: headers,
//     body: urlencoded,
//   };

//   try {
//     const response = await fetch(config.tokenEndpoint.url, requestOptions);
//     if (response && response.ok) {
//       const data = (await response.json()) as OktaApiTokenResponse;
//       if (data) {
//         writeToStorage(
//           KEY_clientToken,
//           data.access_token,
//           'SessionStorage',
//           data.expires_in / 60.0 - 1.0,
//         );
//         return data.access_token;
//       }
//     } else {
//       const d = await response?.json();
//       console.error(
//         `Failed to get client toekn from ${config.tokenEndpoint.url}. Response = `,
//         d,
//       );
//     }
//   } catch (e) {
//     console.error(
//       `Failed to get client token from ${config.tokenEndpoint.url} due to `,
//       e,
//     );
//   }

//   return null;
// };
export const getTokenFromStorage = () => {
  //   if (!window) return undefined;

  // let oktaTokenStorage: string | null = null;
  // if (config.oidc.tokenStorage == undefined && window.localStorage) {
  //   oktaTokenStorage = window.localStorage.getItem(OKTA_TOKEN_STORAGE);
  // } else if (
  //   config.oidc.tokenStorage?.toLocaleLowerCase() ==
  //     'localStorage'.toLocaleLowerCase() &&
  //   window.localStorage
  // ) {
  //   oktaTokenStorage = window.localStorage.getItem(OKTA_TOKEN_STORAGE);
  // } else if (
  //   config.oidc.tokenStorage?.toLocaleLowerCase() ==
  //     'sessionStorage'.toLocaleLowerCase() &&
  //   window.sessionStorage
  // ) {
  //   oktaTokenStorage = window.sessionStorage.getItem(OKTA_TOKEN_STORAGE);
  // } else {
  //   return undefined;
  // }

  // if (oktaTokenStorage) {
  //   const oktaTokens = JSON.parse(oktaTokenStorage);
  //   const oktaAccessToken = oktaTokens?.accessToken?.accessToken;
  //   // LogVerbose('oktaAccessToken = ', oktaAccessToken);
  //   return oktaAccessToken;
  // }
  return window?.localStorage.getItem(KEY_accessToken);
};

export const getApiToken = async () => {
  const userToken = getTokenFromStorage();
  // LogVerbose('oktaUserTokenFromStorage = ', userToken);
  if (userToken) {
    return userToken;
  }

  // const clientToken = readFromStorage<string>(KEY_clientToken);
  // LogVerbose('oktaClientTokenFromStorage = ', clientToken);
  // if (clientToken) {
  //   return clientToken;
  // }

  // return await getClientToken();

  return undefined;
};

export const tryGetErrorMessage = async (res: Response) => {
  if (!res || res.ok) return undefined;

  const defaultMsg = `Status = ${res.status} : ${res.statusText}`;

  try {
    const errObj = await res.json();
    return (errObj as ApiErrorMessage).errorMessage || defaultMsg;
  } catch (e) {
    try {
      return (await res.text()) || defaultMsg;
    } catch (ee) {
      return defaultMsg;
    }
  }
};

export const readApiResponse = async <T>(
  apiOptions: ApiOptions,
  res: Response | undefined | null,
): Promise<Result<T>> => {
  const error = (errorMessage?: string, status?: number) => {
    const msg = apiOptions.description
      ? `${apiOptions.description} at ${apiOptions.url} failed due to ${status}`
      : `Api call at ${apiOptions.url} failed due to ${status}`;

    return errorMessage ? `${msg}. Error: ${errorMessage}` : `${msg}.`;
  };

  if (!res) {
    const errorMessage = '';
    console.error(error(errorMessage));
    return FailedResult<T>(errorMessage);
  }
  if (!res.ok) {
    try {
      const errObj = await res.json();
      const errorMessage = (errObj as ApiErrorMessage).errorMessage;
      const err = error(errorMessage);
      console.error(err);
      return FailedResult<T>(errorMessage, error(err, res.status), errObj);
    } catch (e) {
      console.error(e);
      return FailedResult<T>(undefined, e);
    }
  }
  try {
    return GeneralResult((await res.json()) as T);
  } catch (e) {
    console.error(e);
    return FailedResult<T>(error(`Exception: ${JSON.stringify(e)}`), e);
  }
};

export const callApi = async (
  options: ApiOptions,
): Promise<Response | undefined> => {
  LogVerbose(`Calling API at ${options.url}`);
  try {
    const headers = new Headers();

    if (options.contentType) {
      headers.set('Content-Type', options.contentType);
    } else {
      headers.set('Content-Type', 'application/json;charset=utf-8');
    }

    if (options.token) {
      headers.set('Authorization', options.token);
    }

    if (options.extraHeaders) {
      options.extraHeaders.forEach((header) => {
        headers.set(header.key, header.value);
      });
    }

    const payload =
      options.body !== undefined && options.body !== null
        ? options.asUrlSearchParams === true
          ? (options.body as URLSearchParams)
          : JSON.stringify(options.body)
        : undefined;

    const response = await fetch(options.url, {
      method: options.method || 'GET',
      headers: headers,
      body: payload,
    });

    // LogVerbose('Api response: ', response);
    return response;
  } catch (err) {
    console.error(`Failed to call the api at ${options?.url} due to:`, err);
    return undefined;
  }
};

export const fetchApiResult = async <T>(
  options: ApiOptions,
): Promise<Result<T>> => {
  const res = await callApi(options);
  return await readApiResponse<T>(options, res);
};

export const fetchApiData = async <T>(
  options: ApiOptions,
): Promise<T | undefined> => {
  return (await fetchApiResult<T>(options)).data;
};

export const callSecureApi = async (
  options: ApiOptions,
): Promise<Response | undefined> => {
  try {
    if (!options.token) {
      const oktaToken = await getApiToken();
      // LogVerbose('oktaTokenFromStorage = ', oktaToken);
      if (oktaToken) {
        options.token = oktaToken;
      }
    }
    return await callApi(options);
  } catch (err) {
    console.error('Failed to call secure API due to: ', err);
    return undefined;
  }
};

export const fetchSecureApiResult = async <T>(
  options: ApiOptions,
): Promise<Result<T>> => {
  const res = await callSecureApi(options);
  return await readApiResponse<T>(options, res);
};

export const fetchSecureApiData = async <T>(
  options: ApiOptions,
): Promise<T | undefined> => {
  return (await fetchSecureApiResult<T>(options)).data;
};

