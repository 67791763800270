import moment from 'moment';

import config from '../config/config';
import { KEY_homeImage, KEY_ViewFlag } from '../constants';
import { KeyValuePair } from '../types/interfaces';
import { Result } from '../types/result';
import { RedirectType } from '../types/types';
import { getCurrentLanguge } from './languageService';
import { readFromStorage, writeToStorage } from './storageServies';

export const isString = (variable: unknown) => {
  return typeof variable === 'string' || variable instanceof String;
};

export const isNumeric = (str: number | string | null | undefined) => {
  if (!str)
    return false;
  if (typeof str === 'number')
    return true;
  const f = parseFloat(str);
  return !isNaN(f) && isFinite(f);
};

export const stringMatched = (str1: string, str2: string): boolean => {
  return str1.trim().toLowerCase() === str2.trim().toLowerCase();
};

export const dateStringMatched = (
  dateStr1: string,
  dateStr2: string,
): boolean => {
  const date1 = moment(dateStr1).format('YYYY-MM-DD');
  const date2 = moment(dateStr2).format('YYYY-MM-DD');
  return date1 === date2;
};

export const isValidEmailAddress = (email: string | null | undefined) => {
  if (!email) return false;
  return email.match(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@$&]).{10,}$/);
};

export const isValidPhoneNumber = (phone: string | null | undefined) => {
  if (!phone) return false;
  return phone.match(/\(?\d{3}\)?[-. ]?\d{3}[-. ]?[-. ]?\d{4}/);
};

export const formatUsPhoneNumber = (phone: string): string => {
  // //https://learnersbucket.com/examples/javascript/how-to-format-phone-number-in-javascript/#google_vignette
  // const cleaned = phone.replace(/\D/g, '');
  // const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  // return match ? `(${match[1]}) ${match[2]}-${match[3]}` : phone;
  return formatPhoneNumber(phone, '(A) B-C');
};
export const formatPhoneNumber = (phone: string, pattern: string): string => {
  //https://learnersbucket.com/examples/javascript/how-to-format-phone-number-in-javascript/#google_vignette
  const cleaned = phone.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (!match) {
    return phone;
  }

  return pattern.replace('A', match[1]).replace('B', match[2]).replace('C', match[3]);
};

export const formatShortDateString = (dateStr: string | null | undefined) => {
  if (!dateStr) return undefined;

  const timeStamps = Date.parse(dateStr);
  if (Number.isNaN(timeStamps)) return dateStr;

  const date = new Date(timeStamps);
  return moment(date).format('MM/DD/YYYY');
};

export const FailedResult = <T>(
  message?: string,
  error?: unknown,
  data?: T,
): Result<T> => {
  const result: Result<T> = {
    ok: false,
    message: message,
    error: error,
    data: data,
  };
  return result;
};

export const ToFailedResult = <TIn, TOut>(
  result: Result<TIn>,
): Result<TOut> => {
  const failedResult: Result<TOut> = {
    ok: false,
    message: result?.message,
    error: result?.error,
  };
  return failedResult;
};

export const OkResult = <T>(data: T, message?: string): Result<T> => {
  const result: Result<T> = {
    ok: true,
    data: data,
    message: message,
  };
  return result;
};

export const GeneralResult = <T>(data?: T, message?: string): Result<T> => {
  const result: Result<T> = {
    ok: data !== undefined && data !== null,
    data: data,
    message: message,
  };
  return result;
};

//////////////////////////////////////////////////////////////////////////////////////////////
// A set of functions to check whether or not the current page is loaded within a mobile-app
// Based on the value of the query string parameter "platform"
// Caution: critical dependence on the query string parameter
//////////////////////////////////////////////////////////////////////////////////////////////
export const setViewFlag = () => {
  let platform = readFromStorage<string>(KEY_ViewFlag, 'LocalStorage');
  if (platform) return;

  const queryParams = new URL(window.location.href).searchParams;
  LogVerbose('Query parameters: ', JSON.stringify(queryParams));

  platform = queryParams.get('platform') || queryParams.get('platform');
  if (!platform) {
    // platform = 'browser';
    return;
  }

  writeToStorage(KEY_ViewFlag, platform.toLowerCase(), 'LocalStorage');
};

export const isIosApp = (): boolean => {
  const platform = readFromStorage<string>(KEY_ViewFlag, 'LocalStorage');
  const isIOS = platform != null && platform.toLowerCase() == 'ios';
  return isIOS;
};


//check if the page is loaded within an android WebView
//based on the flag "platform" stored in the LocalStorage
export const isAndroidApp = (): boolean => {
  const platform = readFromStorage<string>(KEY_ViewFlag, 'LocalStorage');
  const isAndroid = platform != null && platform.toLowerCase() == 'android';
  return isAndroid;
};
//###################################################################################################

//////////////////////////////////////////////////////////////////////////////////////////////
// A set of functions to check whether or not the current page is loaded within a mobile-app
// Based on the value of the user agent
// Warning: limited reliability
//////////////////////////////////////////////////////////////////////////////////////////////
export const isIosApp_UA = () => {
  //https://stackoverflow.com/questions/37591279/detect-if-user-is-using-webview-for-android-ios-or-a-regular-browser
  const userAgent = window.navigator.userAgent.toLowerCase();
  const ios = /iphone|ipod|ipad/.test(userAgent);
  if (!ios) {
    return false;
  }
  const safari = /safari/.test(userAgent);
  return !safari;
};

export const isAndroidApp_UA = () => {
  //https://webmasters.googleblog.com/2011/03/mo-better-to-also-detect-mobile-user.html
  const userAgent = window.navigator.userAgent.toLowerCase();
  const android = /android/.test(userAgent);
  if (!android) {
    return false;
  }
  const phone = /mobile/.test(userAgent);
  if (!phone) {
    return false;
  }
  const webview = /wv/.test(userAgent);
  return webview;
};
//##################################################################################################

export const isMobileApp = () => {
  // return true;
  if (config.appconfig.disableBrowserView === true) {
    console.log('Browser view is disabled by app configuration.');
    return true;
  }
  // LogVerbose('user-agent:', window.navigator.userAgent.toLowerCase());
  const isApp = isAndroidApp() || isIosApp();
  // LogVerbose('Is mobile App: ', isApp);
  return isApp;
};


export const getLanguageFromUrl = (url: string) => {
  try {
    //Note: if the url is a partial url, we will get an exception 
    const queryParams = new URL(url).searchParams;
    const languageQueryPara = queryParams?.get('lang');
    if (!languageQueryPara)
      return undefined;
    return languageQueryPara.split('-')[0]?.trim().toLowerCase();
  }
  catch (e) {
    // LogVerbose('Failed to extact Langauge query parameter due to ', e);
    // let langQP: string | undefined = undefined;
    const segs = url.split('?');
    if (segs.length > 1) {
      return segs[1]?.split('&').map((qps) => {
        const qp = qps.split('=');
        if (qp.length == 0) {
          return { name: undefined, value: undefined };
        }
        else if (qp.length == 1) {
          return { name: qp[0]?.trim().toLowerCase(), value: undefined };
        }
        else {
          return { name: qp[0]?.trim().toLowerCase(), value: qp[1]?.trim().toLowerCase() };
        }
      }).find(qpn => qpn.name == 'lang')?.value;
    }
    else {
      return undefined;
    }
  }
};


export const getUrlWithLanguage = (url: string, lang?: string) => {
  if (!url)
    return url;

  url = getUrlWithoutTrailingSlash(url)!;
  const languageQP = getLanguageFromUrl(url);
  if (languageQP)
    return url;

  const lan = lang || getCurrentLanguge() || config.languageConfig.defaultLanguageName;
  if (lan) {
    url = addQueryParameter(url, 'lang', lan);
  }
  return url;
};

export const isAbsoluteUrl = (url: string | null | undefined) => {
  return (url && (url.indexOf('://') > 0 || url.indexOf('//') === 0));
};

export const redirectToUrl = (url?: string, type?: RedirectType) => {
  if (!url)
    return;
  url = getUrlWithLanguage(url);
  if (!url) {
    console.error(`Url is undefined.`);
    return;
  }
  LogVerbose(`Redirecting to ${url} ...`);
  if (type === 'replace') {
    window.location.replace(url);
  } else if (type === 'new') {
    window.open(url, '_blank');
  } else {
    // window.location.href = url;
    window.open(url, '_self');
  }
};

export const getCookies = () => {
  const allCookies = decodeURIComponent(document.cookie)
    .split(';')
    .map((c) => {
      const np = c.split('=');
      return { name: np[0].trim(), value: np[1].trim() };
    });

  return allCookies;
};

export const getCookie = (name: string): string | undefined => {
  const n = name.trim().toLowerCase();
  const allCookies = getCookies();
  const cookie = allCookies.find((c) => c.name.toLowerCase() == n);
  return cookie?.value;
};

export const addQueryParameters = ({
  url,
  queryParameters,
}: {
  url: string;
  queryParameters: KeyValuePair<string>[];
}): string => {
  if (!url)
    return url;

  const queryStringSegs = queryParameters.filter(kvp => kvp.key && kvp.value).map(
    (kvp) => `${encodeURIComponent(kvp.key.trim())}=${encodeURIComponent(kvp.value.trim())}`,
  );
  const queryString = queryStringSegs.join('&');
  url = url.trim().replace(/\/$/, "");
  const joint = url.includes('?') ? '&' : '?';
  url = `${url}${joint}${queryString}`;
  return url;
};

export const addQueryParameter = (
  url: string,
  name: string,
  value: string,
): string => {
  if (!url || !name || !value)
    return url;
  url = url.trim().replace(/\/$/, "");
  const joint = url.includes('?') ? '&' : '?';
  const queryString = `${encodeURIComponent(name.trim())}=${encodeURIComponent(value.trim())}`;
  url = `${url}${joint}${queryString}`;
  return url;
};

// export const emailRegEx =
//   /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

// export const isValidEmailAddress = (email: string): boolean => {
//   return emailRegEx.test(email);
// };

export const isValidPattern = (pattern: string, regex: RegExp): boolean => {
  return regex.test(pattern);
};


export const getRandomImage = (images: string[]): string | undefined => {
  const cached = readFromStorage<string>(KEY_homeImage);
  if (cached) return cached;
  if (!images || images.length == 0) return undefined;
  const index = Math.floor(Math.random() * images.length);
  // As index is always a number, this is no risk of object injection
  // eslint-disable-next-line security/detect-object-injection    
  const headImage = images[index];
  writeToStorage(KEY_homeImage, headImage);
  return headImage;
};

export const getElementAttributes = ({ elementId, attributes }: { elementId: string; attributes: string[] }): KeyValuePair<string | undefined | null>[] | undefined => {
  if (!elementId || !attributes || attributes.length == 0) {
    return undefined;
  }
  const iframe = window.document.getElementById(elementId);
  if (!iframe) {
    LogVerbose(`Failed to get element with ID ${elementId}`);
    return undefined;
  }
  return attributes.map((name): KeyValuePair<string | undefined | null> => {
    const value = iframe.getAttribute(name);
    if (!value) {
      LogVerbose(`Failed to get attribute ${name} for element ${elementId}`);
    }
    return { key: name, value: value };
  });
};

export const getIframeHeightAttribute = (iframeId: string) => {
  const heightAttribute = getElementAttributes({ elementId: iframeId, attributes: ['height'] });
  if (!heightAttribute)
    return undefined;
  return heightAttribute[0].value;
};

export const getIframeContentHeight = (iframeId: string) => {
  const iframe = window.document.getElementById(iframeId);
  if (!iframe) {
    LogVerbose(`Failed to get element with ID ${iframeId}`);
    return undefined;
  }

  const iframeObj = iframe as HTMLObjectElement;
  try {
    const iframeDoc = iframeObj.contentDocument || iframeObj.contentWindow?.document;
    if (!iframeDoc)
      return undefined;
    return iframeDoc.body.scrollHeight;
  }
  catch (e) {
    LogVerbose(`failed to obtain iframe content height due to ${JSON.stringify(e)}`, e);
    return undefined;
  }

};

export const LogVerbose = (msg: string, fmt?: unknown) => {
  if (config.env === 'PRD')
    return;

  console.debug(`%c${msg}`, fmt || 'color:black');
};

export const sleep = (timeInMS: number) => {
  return new Promise((resolve) => setTimeout(resolve, timeInMS));
};


export const isTouchCapable = () => {
  return 'ontouchstart' in window;
};

export const getUrlWithoutTrailingSlash = (url?: string) => {
  return url?.replace(/\/$/, '');
};

export const getUrlWithoutqueryString = (url?: string) => {
  if (!url)
    return url;
  return getUrlWithoutTrailingSlash(url.split('?')[0]);
};

export const isPrintingSupported = () => {
  return window.print != undefined;
};
