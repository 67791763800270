import 'bootstrap/dist/css/bootstrap.css';
import './carousel.scss';

import React, { useContext } from 'react';

import { isTouchCapable } from '../../../services/utilities';
import { CaretDisaplyOption } from '../../../types/types';
import { Swipeable } from '../Swipeable/Swipeable';
import { CarouselCarets } from './CarouselCarets';
import { carouselContext, CarouselContext } from './carouselContext';
import { CarouselDotGroup } from './CarouselDotGroup';

export const CarouselSlide = (
    { tag, children, withCaret, withDotGroup, withFading, withLooping }:
        { tag?: string; withDotGroup?: boolean; withCaret?: CaretDisaplyOption; withFading?: boolean; withLooping: boolean; children: React.ReactNode[] }) => {

    const { name, activeIndex, setActiveIndex, looping } = useContext<CarouselContext>(carouselContext);
    const isLooping = looping && withLooping;

    const nSlides = children.length;
    const verifiedActiveIndex = Math.max(0, Math.min(nSlides - 1, activeIndex));

    const getSlideIndex = (index: number) => {
        if (index < 0) index = isLooping && nSlides ? nSlides - 1 : 0;
        if (index > nSlides - 1) index = isLooping ? 0 : nSlides - 1;
        return index;
    };
    const gotoSlide = (index: number) => {
        setActiveIndex(getSlideIndex(index));
    };

    const gotoPreviousSlide = () => {
        gotoSlide(verifiedActiveIndex - 1);
    };

    const gotoNextSlide = () => {
        gotoSlide(verifiedActiveIndex + 1);
    };

    const getId = (index: number) => {
        let nameTag = name ? name : 'carousel';
        if (tag) {
            nameTag = `${nameTag}_${tag}`;
        }
        return `${nameTag}_${index}`;
    };

    if (nSlides == 0)
        return null;

    const showCaret = withCaret == 'Always' || (withCaret == 'NonTouch' && !isTouchCapable());

    return (
        <carouselContext.Provider value={{ name, looping: isLooping, nSlides, activeIndex: verifiedActiveIndex, setActiveIndex, gotoSlide, gotoPreviousSlide, gotoNextSlide }} >
            <div data-testid='carousel-stage' className='stage'>
                <Swipeable onSwipedLeft={gotoNextSlide} onSwipedRight={gotoPreviousSlide} swipeableClass='w-100 position-relative'>
                    {children.map((slide, index) =>
                        <div data-testid='carousel-slide'
                            className={`w-100 slide ${index == verifiedActiveIndex ? 'active' : 'non-active'} ${withFading ? 'fading' : ''}`}
                            key={index}
                            id={getId(index)}
                            aria-hidden={verifiedActiveIndex != index}
                        >
                            {slide}
                        </div>)
                    }
                    {showCaret && <CarouselCarets />}
                </Swipeable>
            </div>
            {withDotGroup && <CarouselDotGroup />}
        </carouselContext.Provider >
    );
};

