import 'bootstrap/dist/css/bootstrap.css';
import './healthBlog.scss';

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectLanguage } from '../../reduxToolkit/features/languageSlice';
import {
    ContentDictionary, getContentDictionaryInCurrentLanguage
} from '../../services/contentService';
import { redirectToUrl } from '../../services/utilities';
import { BlogInfo } from '../../types/interfaces';
import { CarouselType } from '../../types/types';
import { CarouselProvider } from '../shared/Carousel/CarouselProvider';
import { CarouselScroll } from '../shared/Carousel/CarouselScroll';
import { CarouselSlide } from '../shared/Carousel/CarouselSlide';

export const HealthBlogCarousel = ({ blogs, carouselType, looping = true }: { blogs: BlogInfo[] | undefined; carouselType: CarouselType; looping?: boolean }) => {
    const [contentDictionary, setContentDictionary] = useState<ContentDictionary | null>(null);
    const language = useSelector(selectLanguage);
    useEffect(() => {
        const content = getContentDictionaryInCurrentLanguage('blog');
        if (!content) {
            console.warn('Failed to load the content dictionary.');
        }
        setContentDictionary(content);
    }, [language]);

    if (!blogs)
        return <div className='w-100 h-100 d-flex justify-content-center align-items-center'><p>{contentDictionary?.unavailable || 'Content unavailable'}</p></div>;

    const renderBlogImages = () => blogs.map((blog, index) =>
        <div key={index} className='blog-image-frame'>
            <img className='w-100 d-block' src={blog.imageUrl} alt={`image for ${blog.caption}`}
                loading={index > 0 ? undefined : 'eager'}
            />
        </div>
    );

    const renderBlogMetaInfo = () => blogs.map((blog, index) =>
        <div key={index} className='w-100 d-flex flex-column justify-content-start align-items-start'>
            {blog.caption && <div className='w-100 mb-12 blog-caption'>{blog.caption}</div>}
            {blog.link && <button className='btn primary' onClick={() => redirectToUrl(blog.link, 'new')} >{contentDictionary?.readMore || 'Read More'}</button>}
        </div>
    );

    // const renderBlogs = () => blogs.map((blog, index) =>
    //     <div key={index} className='w-100'>
    //         <div className='blog-image-frame'>
    //             <img className='w-100 d-block' src={blog.imageUrl} alt={`image for ${blog.caption}`}
    //                 loading={index > 0 ? undefined : 'eager'}
    //             />
    //         </div>
    //         <div className='w-100 d-flex flex-column justify-content-start align-items-start'>
    //             {blog.caption && <div className='w-100 mb-12 blog-caption'>{blog.caption}</div>}
    //             {blog.link && <button className='btn primary' onClick={() => redirectToUrl(blog.link, 'new')} >{contentDictionary?.readMore || 'Read More'}</button>}
    //         </div>
    //     </div>
    // );

    return <CarouselProvider looping={looping} name='carousel'>
        <>
            {/* {carouselType == 'Snapshot' ?
                <CarouselSlide withCaret='NonTouch' withLooping={looping} withDotGroup={true} tag='img-snapshot'>
                    {renderBlogs()}
                </CarouselSlide>
                :
                <CarouselScroll withCaret='NonTouch' withLooping={looping} withDotGroup={true} tag='img-scroll'>
                    {renderBlogs()}
                </CarouselScroll>
            } */}
            {carouselType == 'Snapshot' ?
                <CarouselSlide withCaret='NonTouch' withLooping={looping} withDotGroup={false} tag='img-snapshot'>
                    {renderBlogImages()}
                </CarouselSlide>
                :
                <CarouselScroll withCaret='NonTouch' withLooping={looping} withDotGroup={false} tag='img-scroll'>
                    {renderBlogImages()}
                </CarouselScroll>
            }
            {carouselType == 'Snapshot' ?
                <CarouselSlide withCaret='None' withLooping={looping} withDotGroup={true} tag='info-snapshot'>
                    {renderBlogMetaInfo()}
                </CarouselSlide> :
                <CarouselScroll withCaret='None' withLooping={looping} withDotGroup={true} tag='info-snapshot'>
                    {renderBlogMetaInfo()}
                </CarouselScroll>
            }
        </>
    </CarouselProvider>;
};