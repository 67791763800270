import './card.scss';

import React from 'react';

const Card = (props: { children?: React.ReactNode; extraCalss?: string }) => {
  return (
    <div className={`card ${props.extraCalss || ''}`}>{props.children}</div>
  );
};

export default Card;
