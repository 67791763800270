import i18n from 'i18next';

/* eslint-disable quotes */
import config from '../config/config';
import { UsStateList } from '../resources/UsStateList';
import { BlogInfo, EnumOption, LocationDetails } from '../types/interfaces';
import { Dictionary } from '../types/types';
import { ApiOptions, callApi, fetchApiResult } from './apiServices';
import { getCurrentLanguge } from './languageService';
import { readFromStorage, StorageType, writeToStorage } from './storageServies';
import { LogVerbose } from './utilities';

// import { Result } from '../types/result';
// import { addQueryParameter } from './utilities';

export type ContentDictionary = Dictionary<string>;
export type ContentsByPage = { [key: string]: ContentDictionary };
export type ContentsByPageAndLanguage = { [key: string]: ContentsByPage };

export const getContentDictionaryInCurrentLanguage = (
  pageId: string,
): ContentDictionary | null => {
  LogVerbose(`Loading ${pageId} in ${getCurrentLanguge()}`);
  const contents = i18n.t(pageId, { returnObjects: true });
  // LogVerbose(`${pageId} contents:`, JSON.stringify(contents));
  if (contents) return contents as ContentDictionary;
  return null;
};

export const getUsStateList = async (fromApi?: boolean) => {
  if (!fromApi) {
    return UsStateList;
  }

  return getEnumOptions('state');
};

export const getEnumOptions = async (
  enumName: string,
  storageType?: StorageType,
): Promise<EnumOption[]> => {
  const language = getCurrentLanguge();
  LogVerbose(`Loading Enum <${enumName}> in ${language}`);
  const keyName = `EnumOptions_${enumName}_${language}`;
  const cached = readFromStorage<EnumOption[]>(keyName, storageType);
  if (cached) {
    LogVerbose('Using cached enum options for ' + enumName);
    return cached;
  }

  const apiOptions: ApiOptions = {
    url: `${config.apiConfig.baseUrl}/${config.apiConfig.enumOptionsEndPoint}/${enumName}?language=${language}`,
  };

  try {
    const response = await callApi(apiOptions);
    if (!response || !response.ok) return [];

    const data = (await response.json()) as EnumOption[];
    if (data) writeToStorage(keyName, data, storageType);
    return data;
  } catch (e: unknown) {
    console.error(
      `Failed to get enum options for ${enumName} from ${apiOptions.url}`,
      e,
    );
    return [];
  }
};

export const getEnumDisplayAsync = async (
  enumName: string,
  enumValue: string | null | undefined,
) => {
  if (!enumValue) return null;
  const options = await getEnumOptions(enumName);
  const option = options.find(
    (o) => o.value === enumValue || o.display === enumValue,
  );
  return option?.display;
};

export const getEnumDisplay = (
  options: EnumOption[],
  enumValue: string | null | undefined,
) => {
  if (!enumValue) return undefined;

  const option = options.find(
    (o) => o.value === enumValue || o.display === enumValue,
  );
  return option?.display;
};

export const loadGetCareNowDetails = async (): Promise<
  LocationDetails[] | undefined
> => {
  const language = getCurrentLanguge();
  LogVerbose(`Loading GetCareNow detail in ${language}`);
  const keyName = `GetCareNowDetails_${language}`;
  const cached = readFromStorage<LocationDetails[]>(keyName, 'SessionStorage');
  if (cached) {
    LogVerbose('Using cached info for GetCareNow');
    return cached;
  }

  const url = `${config.apiConfig.baseUrl}/${config.apiConfig.locationDetailsEndPoint}?language=${language}`;
  const apiOptions: ApiOptions = {
    url: url,
  };

  const result = await fetchApiResult<LocationDetails[]>(apiOptions);

  if (!result.ok) {
    return undefined;
  }
  console.log('api result: ', result);

  const locationDetails = result.data!;
  writeToStorage(keyName, locationDetails);

  return locationDetails;
};

export const loadHealthBlog = async (): Promise<BlogInfo[] | undefined> => {

  const language = getCurrentLanguge();

  LogVerbose(`Loading Health Blog info in ${language}`);
  const keyName = `HeathBlogInfoDetails_${language}`;
  const cached = readFromStorage<BlogInfo[]>(keyName, 'SessionStorage');
  if (cached) {
    LogVerbose('Using cached info for Health Blog');
    return cached;
  }


  const url = `${config.apiConfig.baseUrl}/${config.apiConfig.healthBlogInfoEndpoint}?language=${language}`;
  const apiOptions: ApiOptions = {
    url: url,
  };

  const result = await fetchApiResult<BlogInfo[]>(apiOptions);
  if (!result.ok) {
    return undefined;
  }
  console.log('api result: ', result);

  const healthBlogInfo = result.data!;
  writeToStorage(keyName, healthBlogInfo);

  return healthBlogInfo;
};
