import './spinner.scss';

import React from 'react';

function MyMhSpinner() {
  return (
    <div className='mh-spinner-container'>
      <div className='mh-spinner'></div>
    </div>
  );
}

export default MyMhSpinner;
