import React from 'react';

const PageNotFound = () => {
  return (
    <div className='container'>
      <div className='alert alert-warning' role='alert'>
        <h3 className='text-center'>Page Not Found!</h3>
      </div>
    </div>
  );
};

export default PageNotFound;
