import 'bootstrap/dist/css/bootstrap.css';

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { stringify } from 'uuid';

import { selectLanguage } from '../../reduxToolkit/features/languageSlice';
import { loadHealthBlog } from '../../services/contentService';
import { LogVerbose } from '../../services/utilities';
import { BlogInfo } from '../../types/interfaces';
import { CarouselType } from '../../types/types';
import MyMhSpinner from '../shared/Spinner/MyMhSpinner';
import { HealthBlogCarousel } from './HealthBlogCarousel';

export const HealthBlog = ({ carouselType, onError, looping }: { carouselType: CarouselType; onError?: (msg?: string) => void; looping?: boolean; }) => {
    const [blogInfo, setBlogInfo] = useState<BlogInfo[] | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const language = useSelector(selectLanguage);
    useEffect(() => {
        LogVerbose(`Health & Wellness: language = ${language}`);
        loadHealthBlog()
            .then((data) => {
                if (data)
                    setBlogInfo(data);
                else {
                    const msg = "No health & wellness data is available.";
                    console.error(msg);
                    if (onError)
                        onError(msg);
                }
            })
            .catch((error) => {
                console.log(error);
                if (onError) {
                    onError(stringify(error));
                }
            })
            .finally(() => setIsLoading(false));
    }, [language]);
    if (isLoading)
        return <MyMhSpinner />;

    // return <>
    //     <HealthBlogCarousel blogs={blogInfo} carouselType='Scroll' looping={false} />
    //     <div className='divider'></div>
    //     <HealthBlogCarousel blogs={blogInfo} carouselType='Snapshot' looping={true} />
    // </>;
    return <HealthBlogCarousel blogs={blogInfo} carouselType={carouselType} looping={looping} />;
};