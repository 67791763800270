import 'bootstrap/dist/css/bootstrap.css';
import './round-action-box.scss';

import React from 'react';

const RoundActionBox = ({
  tabIndex,
  iconPath,
  label,
  onClick,
  extraCssClass,
}: {
  tabIndex?:number,
  iconPath: string;
  label: string | undefined;
  description?: string;
  onClick?: () => void;
  extraCssClass?: string;
}) => {
  return (
    <button
      className={`round-action-box ${extraCssClass || ''}`}
      onClick={onClick}
      tabIndex={tabIndex}
    >
      <div className='round-action-icon-frame'>
        <img src={iconPath} alt='' aria-hidden={true} className='round-action-icon' />
      </div>
      <div className='round-action-label'>{label}</div>
    </button>
  );
};

export default RoundActionBox;
