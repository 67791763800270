import './Navigation.scss';

import React, { MouseEventHandler } from 'react';
import { useHistory } from 'react-router-dom';

import config from '../../../config/config';
import { isMobileApp } from '../../../services/utilities';
// import backIcon from './Back Icon.svg';
import homeIcon from './Home Icon.svg';

interface INavigation {
  navigationTitle: string;
  showBackButton: boolean;
  showHomeButton: boolean;
  showSignOutLink?: boolean;
  onBackButtonClicked?: () => void;
  onSignOutButtonClicked?: () => void;
}

const Navigation = ({
  navigationTitle,
  showBackButton,
  showHomeButton,
  showSignOutLink,
  onBackButtonClicked,
  onSignOutButtonClicked,
}: INavigation): JSX.Element => {
  const history = useHistory();
  const { urlConfig } = config;

  const onBackButtonClickedHandler: MouseEventHandler<HTMLElement> = () => {
    if (onBackButtonClicked) {
      onBackButtonClicked();
    } else {
      history.goBack();
    }
  };

  const onSignOutButtonClickedHandler: MouseEventHandler<HTMLElement> = () => {
    if (onSignOutButtonClicked) {
      onSignOutButtonClicked();
    } else {
      history.push(config.urlConfig.logout);
    }
  };

  const handleHomeButtonClick = () => {
    history.push(urlConfig.home);
  };

  const browserView = !isMobileApp();
  if (browserView) {
    showHomeButton = false;
  }

  const renderGoBackButton = () => <span aria-label='go back' role='button'>
    <svg
      width='18'
      height='18'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20.9996 11.9999C20.9996 12.1988 20.9206 12.3896 20.7799 12.5303C20.6393 12.6709 20.4485 12.7499 20.2496 12.7499H5.55993L11.0302 18.2193C11.0999 18.289 11.1552 18.3717 11.1929 18.4628C11.2306 18.5538 11.25 18.6514 11.25 18.7499C11.25 18.8485 11.2306 18.9461 11.1929 19.0371C11.1552 19.1281 11.0999 19.2109 11.0302 19.2806C10.9606 19.3502 10.8778 19.4055 10.7868 19.4432C10.6957 19.4809 10.5982 19.5003 10.4996 19.5003C10.4011 19.5003 10.3035 19.4809 10.2124 19.4432C10.1214 19.4055 10.0387 19.3502 9.96899 19.2806L3.21899 12.5306C3.14926 12.4609 3.09394 12.3782 3.05619 12.2871C3.01845 12.1961 2.99902 12.0985 2.99902 11.9999C2.99902 11.9014 3.01845 11.8038 3.05619 11.7127C3.09394 11.6217 3.14926 11.539 3.21899 11.4693L9.96899 4.7193C10.1097 4.57857 10.3006 4.49951 10.4996 4.49951C10.6986 4.49951 10.8895 4.57857 11.0302 4.7193C11.171 4.86003 11.25 5.05091 11.25 5.24993C11.25 5.44895 11.171 5.63982 11.0302 5.78055L5.55993 11.2499H20.2496C20.4485 11.2499 20.6393 11.3289 20.7799 11.4696C20.9206 11.6103 20.9996 11.801 20.9996 11.9999Z'
        fill='#2C373F'
      />
    </svg>
  </span>;

  const renderNavigation = () => (
    <nav className='mh-navbar'>
      <div className='mh-nav-container'>
        <div className='mh-nav'>
          <div className='mh-nav-items'>

            {showBackButton && (
              <div className='mh-nav-back-icon' onClick={onBackButtonClickedHandler} >
                {renderGoBackButton()}
              </div>
            )}
            <div className='mh-nav-title'>{navigationTitle}</div>
            {showHomeButton && (
              <div className='mh-nav-home-icon'>
                <img
                  src={homeIcon}
                  alt='Home'
                  onClick={handleHomeButtonClick}
                />
              </div>
            )}
            {showSignOutLink && (
              <div className='mh-nav-home-icon'>
                <button
                  type='button'
                  className='btn btn-link'
                  onClick={onSignOutButtonClickedHandler}
                >
                  Sign out
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );

  const renderBrowserNavigation = () => (
    <nav className='mh-navbar-browser padding-x'>
      <div className='nav-items-browser'>
        {showBackButton && (
          <div
            className='nav-back-browser'
            onClick={onBackButtonClickedHandler}
          >
            {renderGoBackButton()}
            <span className='nav-text-browser'>Back</span>
          </div>
        )}
        {showSignOutLink && (
          <button
            type='button'
            className='btn btn-link nav-text-browser sign-out'
            onClick={onSignOutButtonClickedHandler}
          >
            Sign out
          </button>
        )}
      </div>
      <div className='nav-title-browser'>{navigationTitle}</div>
    </nav>
  );

  return browserView ? renderBrowserNavigation() : renderNavigation();
};

export default Navigation;
