import config from '../config/config';
import { KEY_scheduleInfo } from '../constants';
// import { KEY_scheduleInfo } from '../constants';
import { BlockItResponse, ScheduleInfo } from '../types/interfaces';
import { Result } from '../types/result';
import { PatientSchedulingCategory } from '../types/types';
import { ApiOptions, fetchSecureApiResult } from './apiServices';
import { readFromStorage, removeFromStorage, writeToStorage } from './storageServies';
import { OkResult } from './utilities';
// import { readFromStorage, writeToStorage } from './storageServies';
// import { OkResult } from './utilities';

export const getPersonalizedSchedulingUrl = async (
  epicContextType: PatientSchedulingCategory,
  epicContextToken: string | null,
  service?: string,
): Promise<Result<BlockItResponse>> => {

  let url = `${config.apiConfig.baseUrl}/${config.apiConfig.schedulingEndpoint}?type=${epicContextType}`;
  if (epicContextToken) {
    url = `${url}&epicContextToken=${epicContextToken}`;
  }
  if (service) {
    url = `${url}&service=${service}`;
  }
  const apiOptions: ApiOptions = {
    url: url,
  };

  return await fetchSecureApiResult<BlockItResponse>(apiOptions);
};


export const getSchedules = async ({ epicContextToken }:
  { epicContextToken?: string | null }
): Promise<Result<ScheduleInfo[]>> => {
  const env = (config.env || '').toLowerCase();
  const isProd = env === 'prd' || env === 'prod';

  if (isProd) {
    if (!epicContextToken) {
      return { ok: false, message: 'Epic context token is missing.' };
    }

    const cached = readFromStorage<ScheduleInfo[]>(KEY_scheduleInfo, 'SessionStorage');
    if (cached) {
      return OkResult(cached);
    }
  }

  let url = null;
  if (epicContextToken) {
    url = `${config.apiConfig.baseUrl}/${config.apiConfig.visitsEndpoint}?epicContextToken=${epicContextToken}`;
  }
  else {
    url = `${config.apiConfig.baseUrl}/${config.apiConfig.visitsEndpoint}/all`;
  }

  const apiOptions: ApiOptions = {
    url: url,
  };

  const scheduleInfoResult = await fetchSecureApiResult<ScheduleInfo[]>(apiOptions);
  if (isProd) {
    if (scheduleInfoResult.ok) {
      writeToStorage(KEY_scheduleInfo, scheduleInfoResult.data, 'SessionStorage');
    }
    else {
      removeFromStorage(KEY_scheduleInfo, 'SessionStorage');
    }
  }

  return scheduleInfoResult;
};

