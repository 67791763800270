import 'bootstrap/dist/css/bootstrap.css';
import './scheduling.scss';

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import config from '../../config/config';
import { selectLanguage } from '../../reduxToolkit/features/languageSlice';
import {
  ContentDictionary, getContentDictionaryInCurrentLanguage
} from '../../services/contentService';
import { addQueryParameter, isMobileApp } from '../../services/utilities';
// import Navigation from '../shared/Navigation/Navigation';
import { SchedulingType } from '../../types/types';
import IFramedScreen from '../shared/IFramedScreen/IFramedScreen';
import MyMhSpinner from '../shared/Spinner/MyMhSpinner';

const Scheduling = ({ type }: { type: SchedulingType }) => {
  const [contentDictionary, setContentDictionary] =
    useState<ContentDictionary | null>(null);
  const history = useHistory();
  const language = useSelector(selectLanguage);
  useEffect(() => {
    const content = getContentDictionaryInCurrentLanguage('scheduling');
    if (!content) {
      console.warn('Failed to load the content dictionary.');
    }
    setContentDictionary(content);
  }, [language]);

  const isUrgentScheduling = type === 'UrgentScheduling';
  const isBrowserView = !isMobileApp();

  const pageHeader = isUrgentScheduling
    ? contentDictionary?.urgentScheduling
    : contentDictionary?.scheduling;

  let url = isUrgentScheduling
    ? config.urlConfig.blockItQuickCareUrl
    : config.urlConfig.blockItUrl;

  let noBack = true;
  let noHome = false;
  const queryParams = new URL(window.location.href).searchParams;

  let onBackButtonClicked: (() => void) | undefined = undefined;
  if (isUrgentScheduling) {
    // noBack = false;
    // noHome = false;
    noBack = !isBrowserView;
    noHome = !isBrowserView;
    onBackButtonClicked = undefined;
    const seg = window.location.href.split('?');
    if (seg.length > 1 && seg[1]) {
      url = `${url}/search?${seg[1]}`;
    }
  }
  else {
    // const token = queryParams.get('token') || queryParams.get('Token');
    // if (token) {
    //   url = `${url}/t/${token}`;
    // }

    const frameSrc = queryParams.get('frameSrc');
    if (frameSrc) {
      url = decodeURIComponent(frameSrc);
    }
    noBack = queryParams.get('noBack') == 'true';
    noHome = frameSrc != null;
    onBackButtonClicked = () => { history.push(config.urlConfig.home); };
  }

  const skipPrescreen = queryParams.get('skipPrescreen') == 'true';
  if (skipPrescreen) {
    //url = `${url}?skipPrescreen=true`;
    url = addQueryParameter(url, 'skipPrescreen', 'true');
  }

  if (!contentDictionary) return <MyMhSpinner />;

  return (
    <IFramedScreen
      pageName={pageHeader || ''}
      showBackButton={!noBack}
      onBackButtonClicked={onBackButtonClicked}
      showHomeButton={!noHome}
      url={url}
      extraClass='no-padding'
    />
  );
};

export default Scheduling;
