import { v4 as uuidv4 } from 'uuid';

/* eslint-disable indent */
import config from '../config/config';
import { KEY_sessionUser, KEY_sessionUserId } from '../constants';

export interface DataWithExpiry<T> {
  data: T;
  expiresAt: number;
}
export type StorageType = 'SessionStorage' | 'LocalStorage';

export const setSessionUser = (sessionUserId: string) => {
  removeFromPersonalizedStorage(KEY_sessionUser);
  writeToStorage(KEY_sessionUserId, sessionUserId);
};

export const getPersonalizedStorageKey = (key: string, userId?: string) => {
  let sessionUserId = userId || readFromStorage<string>(KEY_sessionUserId);
  if (!sessionUserId) {
    sessionUserId = uuidv4();
    writeToStorage(KEY_sessionUserId, sessionUserId);
  }

  return `${key}_${sessionUserId}`;
};

export const checkExpiry = (expiry: number) => {
  return new Date().getTime() > expiry;
};

export const clearStorage = (storageType?: StorageType) => {
  if (storageType == 'LocalStorage') {
    localStorage.clear();
  } else {
    sessionStorage.clear();
  }
};

export const calculateDataExpiry = (
  storageType?: StorageType,
  expiryInMinutes?: number,
) => {
  const time =
    expiryInMinutes != undefined && expiryInMinutes > 0
      ? expiryInMinutes * 60 * 1000
      : storageType == 'LocalStorage'
        ? config.storageConfig.localStorageExpiresInDays * 24 * 3600 * 1000
        : config.storageConfig.sessionStorageExpiresInMinutes * 60 * 1000;
  return new Date().getTime() + time;
};

export const writeToPersonalizedStorage = <T>(
  key: string,
  payload: T,
  storageType?: StorageType,
  expiryInMinutes?: number,
) => {
  writeToStorage(
    getPersonalizedStorageKey(key),
    payload,
    storageType,
    expiryInMinutes,
  );
};

export const writeToStorage = <T>(
  key: string,
  payload: T,
  storageType?: StorageType,
  expiryInMinutes?: number,
) => {
  const item: DataWithExpiry<T> = {
    expiresAt: calculateDataExpiry(storageType, expiryInMinutes),
    data: payload,
  };
  const json = JSON.stringify(item);
  if (storageType == 'LocalStorage') {
    localStorage.setItem(key, json);
  } else {
    sessionStorage.setItem(key, json);
  }
};
export const readFromPersonalizedStorage = <T>(
  key: string,
  storageType?: StorageType,
): T | null => {
  return readFromStorage(getPersonalizedStorageKey(key), storageType);
};

export const readFromStorage = <T>(
  key: string,
  storageType?: StorageType,
): T | null => {
  const json =
    storageType == 'LocalStorage'
      ? localStorage.getItem(key)
      : sessionStorage.getItem(key);
  if (!json) return null;

  const item = JSON.parse(json) as DataWithExpiry<T>;
  if (checkExpiry(item.expiresAt)) {
    // console.debug(`${key} expired at ${new Date(item.expiresAt)}`);
    removeFromStorage(key, storageType);
    return null;
  } else return item.data;
};

export const removeFromPersonalizedStorage = (
  key: string,
  storageType?: StorageType,
) => {
  removeFromStorage(getPersonalizedStorageKey(key), storageType);
};

export const removeFromStorage = (key: string, storageType?: StorageType) => {
  if (storageType == 'LocalStorage') {
    localStorage.removeItem(key);
  } else {
    sessionStorage.removeItem(key);
  }
};
