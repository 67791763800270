
import { toast } from 'react-toastify';

import {
  DefaultScreenSizes, KEY_debug, KEY_homeImage, KEY_patient, KEY_patient_name
} from '../constants';
import { ImageSrcSet } from '../types/interfaces';
import { clearStorage, removeFromStorage } from './storageServies';

const resetSettings = () => {
  // setPreferredLanguage(config.languageConfig.defaultLanguageName);
};

export const cleanupSession = () => {
  console.log('Performing clean-up actions ...');
  clearStorage('SessionStorage');
  resetSettings();
};

export const clearPatientSession = () => {
  removeFromStorage(KEY_homeImage);
  removeFromStorage(KEY_debug);
  removeFromStorage(KEY_patient);
  removeFromStorage(KEY_patient_name);
};



export const toastError = (errorMessage: string, timeout?: number) => {
  toast.error(errorMessage, {
    toastId: 2,
    theme: 'colored',
    icon: '',
    autoClose: timeout || false,
    hideProgressBar: true,
  });
};

export const toastResponse = async (successMessage: string) => {
  toast.success(successMessage, {
    toastId: 1,
    theme: 'colored',
    icon: '',
    autoClose: 1500,
    hideProgressBar: true,
  });
};

/*
$bs-sm-min: 576px;
$bs-md-min: 768px;
$bs-lg-min: 992px;
$bs-xl-min: 1200px;
*/


export const getImageSrcset = ({ imageName, sameSize = true }: { imageName: string; sameSize?: boolean }): ImageSrcSet => {

  const seg = imageName.split('.');
  let name = imageName;
  let ext = '';
  if (seg.length > 1) {
    name = seg.slice(0, seg.length - 1).join('.');
    ext = seg[seg.length - 1];
  }

  const srcsetStr = DefaultScreenSizes.map(size => `${name}-${size.label}.${ext} ${size.width}w`).join(', ');
  let sizesStr = undefined;
  if (!sameSize) {
    const sizesArray: string[] = DefaultScreenSizes.length == 1 ? [`${DefaultScreenSizes[0]}px`] : DefaultScreenSizes.slice(0, DefaultScreenSizes.length - 1).map(size => `(max-width: ${size.width}px) ${size.width}px`);
    if (DefaultScreenSizes.length > 1) { sizesArray.push(`${DefaultScreenSizes.slice(DefaultScreenSizes.length - 1)[0].width}px`); }
    sizesStr = sizesArray.join(', ');
  }

  const result = {
    srcSet: srcsetStr,
    sizes: sizesStr,
    src: `${name}-${DefaultScreenSizes[0].label}.${ext} ${DefaultScreenSizes[0].width}w`
  };

  return result;
};