import './myMhBanner.scss';

import React from 'react';

import logo from './Splash Screen Logo.svg';

const MyMhBanner = () => {
  return (
    <div className='mymh-banner'>
      <img className='mymh-logo' src={logo} alt='My Memorial Hermann'  loading='lazy'/>
    </div>
  );
};

export default MyMhBanner;
