//inspired by https://github.com/mandarini/swipe-carousel/tree/master?tab=readme-ov-file
import 'bootstrap/dist/css/bootstrap.css';

import React, { useState } from 'react';

import { LogVerbose } from '../../../services/utilities';

interface SwipeableProps {
    children?: React.ReactNode | React.ReactNode[];
    onTouch?: () => void;
    onSwipedLeft?: (deltaX?: number) => void;
    onSwipedRight?: (deltaX?: number) => void;
    onSwipedUp?: (deltaY?: number) => void;
    onSwipedDown?: (deltaY?: number) => void;
    onSwiping?: ({ deltaX, deltaY }: { deltaX: number; deltaY: number }) => void;
    swipeableClass?: string;
}

interface SwipeStart {
    xStart: number;
    yStart: number;
}

export const Swipeable = ({ children, onSwipedLeft, onSwipedRight, onSwipedUp, onSwipedDown, onSwiping, onTouch, swipeableClass }: SwipeableProps) => {

    const [swipeStart, setSwipeStart] = useState<SwipeStart | undefined>(undefined);
    const [swiping, setSwiping] = useState<boolean>(false);

    const onTouchStart = (e: React.TouchEvent) => {
        const touch = e.touches[0];
        setSwipeStart({ xStart: touch.clientX, yStart: touch.clientY });
        if (onTouch) {
            onTouch();
        }
    };
    const onTouchMove = (e: React.TouchEvent) => {
        if (e.changedTouches && e.changedTouches.length) {

            if (!swiping)
                setSwiping(true);
            if (swipeStart) {
                const changedTouch = e.changedTouches[0];
                const deltaX = changedTouch.clientX - swipeStart.xStart;
                const deltaY = changedTouch.clientY - swipeStart.yStart;
                LogVerbose(`Swiping... deltaX=${deltaX}, deltaY=${deltaY}`);
                if (onSwiping)
                    onSwiping({ deltaX: deltaX, deltaY: deltaY });
            }
            else {
                LogVerbose('Swiping...');
            }
        }
    };
    const onTouchEnd = (e: React.TouchEvent) => {
        if (swiping && swipeStart) {
            const changedTouch = e.changedTouches[0];
            if (changedTouch.clientX > swipeStart.xStart) {
                LogVerbose('Swiped Right');
                if (onSwipedRight)
                    onSwipedRight(changedTouch.clientX - swipeStart.xStart);
            }

            if (changedTouch.clientX < swipeStart.xStart) {
                LogVerbose('Swiped Left');
                if (onSwipedLeft)
                    onSwipedLeft(swipeStart.xStart - changedTouch.clientX);
            }
            if (changedTouch.clientY > swipeStart.yStart) {
                LogVerbose('Swiped Down');
                if (onSwipedDown)
                    onSwipedDown(changedTouch.clientY - swipeStart.yStart);
            }

            if (changedTouch.clientY < swipeStart.yStart) {
                LogVerbose('Swiped Up');
                if (onSwipedUp)
                    onSwipedUp(swipeStart.yStart - changedTouch.clientY);
            }
        }
        setSwiping(false);
        setSwipeStart(undefined);
    };

    const onTouchCancel = () => {
        setSwiping(false);
        setSwipeStart(undefined);
    };

    return (
        <div className={swipeableClass} onTouchCancel={onTouchCancel} onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
            {children}
        </div>

    );
};