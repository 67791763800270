import { KeyValuePair } from '../types/interfaces';
import settings from './settings.json';

export interface StorageConfig {
  sessionStorageExpiresInMinutes: number;
  localStorageExpiresInDays: number;
}

// export type Language = 'en' | 'es';
export interface LanguageOption {
  name: string;
  description: string;
  iconLabel: string;
  iconFile: string;
}

export interface LanguageConfig {
  defaultLanguageName: string;
  languages: LanguageOption[];
}

export interface OidcConfig {
  clientId: string;
  baseUrl: string;
  issuer: string;
  redirectUri: string;
  scopes: string[];
  pkce?: boolean;
  codeChallenge: string;
  challengeMethod: string;
  state: string;
  autoRenew?: boolean;
  tokenStorage?: string;
}

export interface SMART_Config {
  clientId: string;
  scope: string;
}

export interface TokenEndpointConfig {
  url: string;
  auth: string;
  payload: KeyValuePair<string>[];
}

export interface ApiConfig {
  baseUrl: string;
  userEndpoint: string;
  accountEndpoint: string;
  schedulingEndpoint: string;
  visitsEndpoint: string;
  enumOptionsEndPoint: string;
  locationDetailsEndPoint: string;
  skipActivationCodeValidation?: boolean;
  healthBlogInfoEndpoint: string;
}

export interface UrlConfig {
  root: string;
  createAccount: string;
  instantActivation: string;
  linkExpired: string;
  emailVerification: string;
  resetPasswordCallback: string;
  resetPassword: string;
  changePassword: string;
  mfaUpdate: string;
  mfaEnrollment: string;
  biometrics: string;
  myChartEnrollment: string;
  registrationConfirmation: string;
  home: string;
  patientHome: string;
  scheduling: string;
  quickcareScheduling: string;
  schedulingGuide: string;
  getCareNow: string;

  prescriptions: string;
  urgentCare: string;
  virtualUrgentCare: string;
  goHealth: string;
  goHealthUcUrl: string;
  termsOfUse: string;
  privacyPolicy: string;
  resendCode: string;
  login: string;
  loginHelp: string;
  loginWithRedirect: string;
  loginCallback: string;
  logout: string;
  logoutCallback: string;
  profile: string;
  personalInformation: string;
  contactInformation: string;
  demographicInformation: string;
  imageScheduling: string;
  screeningScheduling: string;
  therapyScheduling: string;
  programsScheduling: string;
  mhProgramsAndClasses: string;
  supportRoute: string;
  supportUrl: string;

  blockItUrl: string;
  blockItQuickCareUrl: string;
  resetBiometrics: string;
  securitySettings: string;
  epicSignUp: string;
  epicAccessCheck: string;

  gotoMychartLogin: string;
  gotoMychartHome: string;
  myChartHomeUrl: string;
  myChartLoginUrl: string;
  myChartLoginDeepLink: string;
  myChartHomeDeepLink: string;

  gotoTestResult: string;
  myChartTestResults: string;
  myChartTestResultsDeepLink: string;

  payAsGuest: string;
  mhmgBillPay: string;
  mhHospitalsBillPay: string;
  myChartGuestBillPay: string;

  payBill: string;
  myChartPayBill: string;
  myChartPayBillDeeplink: string;

  guestCostEstimate: string;
  myChartGuestCostEstimate: string;

  costEstimate: string;
  myChartCostEstimate: string;
  myChartCostEstimateDeeplink: string;

  tsBariatrics: string;
  tsCardiology: string;
  tsEndocrinology: string;
  tsGastroenterology: string;
  tsGeneralSurgery: string;
  tsNeurology: string;
  tsPediatricsWellChild: string;
  tsPediatricsBHV: string;
  tsUrology: string;
  tsFamilyMedicine: string;
  tsOBGYN: string;
  tsAllergyImmunology: string;
  tsInfectiousDisease: string;
  tsRheumatology: string;

  functionIsComing: string;
  visits: string;
  blog: string;
  surveyRoute: string;
  surveyLink: string;
}

export interface PageNames {
  home: string;
  createAccount: string;
  getCareNow: string;
  scheduling: string;
  urgentCare: string;
  virtualUrgentCare: string;
  accountSettings: string;
  personalInformation: string;
  contactInformation: string;
  demographicInformation: string;
}

// export interface BlockitConfig {
//   baseUrl: string;
//   fuzzySearchEndpoint: string;
//   redirectUrl: string;
// }

export interface ProfileFieldAttributes {
  name: string;
  label: string;
}
export interface AddressFieldsAttributes {
  name: string;
  label: string;
  addressLine1: ProfileFieldAttributes;
  addressLine2: ProfileFieldAttributes;
  city: ProfileFieldAttributes;
  state: ProfileFieldAttributes;
  postalCode: ProfileFieldAttributes;
}
export interface ProfileFields {
  firstName: ProfileFieldAttributes;
  lastName: ProfileFieldAttributes;
  dateOfBirth: ProfileFieldAttributes;
  legalSex: ProfileFieldAttributes;
  preferredFirstName: ProfileFieldAttributes;
  preferredPronouns: ProfileFieldAttributes;
  language: ProfileFieldAttributes;
  address: AddressFieldsAttributes;
  email: ProfileFieldAttributes;
  mobile: ProfileFieldAttributes;
  race: ProfileFieldAttributes;
  ethnicity: ProfileFieldAttributes;
  genderIdentity: ProfileFieldAttributes;
  sexualOrientation: ProfileFieldAttributes;
  maritalStatus: ProfileFieldAttributes;
  religion: ProfileFieldAttributes;
}

export interface CachingConfig {
  formData: boolean;
}

export interface AppConfig {
  disableBrowserView?: boolean | null;
}

const config: {
  env: string;
  guestOnly: boolean;
  disableSelfEnrollment: boolean;
  disableBiometrics: boolean;
  appconfig: AppConfig;
  cachingConfig: CachingConfig;
  storageConfig: StorageConfig;
  // oidc: OidcConfig;
  smart: SMART_Config;
  tokenEndpoint: TokenEndpointConfig;
  urlConfig: UrlConfig;
  apiConfig: ApiConfig;
  languageConfig: LanguageConfig;
  // blockitConfig: BlockitConfig;
  pageNames: PageNames;
  profileFields: ProfileFields;
} = {
  env: process.env.REACT_APP_ENVIRONMENT || 'Unspecified',
  guestOnly:
    `${process.env.REACT_APP_GUESTONLY || ''}`.trim().toLowerCase() == 'true',
  disableSelfEnrollment:
    `${process.env.REACT_APP_DISABLE_SELF_ENROLLMENT || ''}`
      .trim()
      .toLowerCase() == 'true',

  disableBiometrics:
    `${process.env.REACT_APP_DISABLE_BIOMETRICS || ''}`.trim().toLowerCase() ==
    'true',
  appconfig: {
    ...settings.appConfig,
    disableBrowserView: process.env.REACT_APP_DISABLE_BROWSER_VIEW
      ? process.env.REACT_APP_DISABLE_BROWSER_VIEW == 'true'
      : undefined,
  },
  cachingConfig: {
    ...settings.cachingConfig,
  },
  storageConfig: {
    ...settings.storageConfig,
    sessionStorageExpiresInMinutes: process.env
      .REACT_APP_SESSION_STORAGE_EXPIRES_MINUTES
      ? Number(process.env.REACT_APP_SESSION_STORAGE_EXPIRES_MINUTES)
      : settings.storageConfig.sessionStorageExpiresInMinutes,
    localStorageExpiresInDays: process.env.REACT_APP_LOCAL_STORAGE_EXPIRES_DAYS
      ? Number(process.env.REACT_APP_LOCAL_STORAGE_EXPIRES_DAYS)
      : settings.storageConfig.localStorageExpiresInDays,
  },
  // oidc: {
  //   ...settings.oidc,
  //   baseUrl: process.env.REACT_APP_OIDC_BASE_URL || settings.oidc.baseUrl,
  //   redirectUri: `${window.location.origin}${settings.oidc.redirectUri}`,
  //   clientId: process.env.REACT_APP_OIDC_CLIENT_ID || settings.oidc.clientId,
  //   issuer:
  //     process.env.REACT_APP_OIDC_BASE_URL &&
  //     process.env.REACT_APP_OIDC_ISSUER_ENDPOINT
  //       ? `${process.env.REACT_APP_OIDC_BASE_URL}${process.env.REACT_APP_OIDC_ISSUER_ENDPOINT}`
  //       : settings.oidc.issuer,
  // },
  smart: {
    ...settings.smart,
    clientId: process.env.REACT_APP_EPIC_FHIR_CLIENT_ID || settings.smart.clientId,
  },
  tokenEndpoint: {
    ...settings.tokenEndpoint,
    url:
      process.env.REACT_APP_OIDC_BASE_URL &&
        process.env.REACT_APP_TOKEN_ENDPOINT
        ? `${process.env.REACT_APP_OIDC_BASE_URL}${process.env.REACT_APP_TOKEN_ENDPOINT}`
        : settings.tokenEndpoint.url,
    auth:
      process.env.REACT_APP_TOKEN_ENDPOINT_AUTH || settings.tokenEndpoint.auth,
  },
  urlConfig: {
    ...settings.urlConfig,
    myChartHomeUrl:
      process.env.REACT_APP_MYCHART_HOME ||
      settings.urlConfig.myChartHomeUrl,
    myChartLoginUrl: process.env.REACT_APP_MYCHART_SIGNIN ||
      settings.urlConfig.myChartLoginUrl,
    myChartLoginDeepLink:
      process.env.REACT_APP_MYCHART_SIGNIN_DEEPLINK ||
      settings.urlConfig.myChartLoginDeepLink,
    myChartHomeDeepLink:
      process.env.REACT_APP_MYCHART_HOME_DEEPLINK ||
      settings.urlConfig.myChartHomeDeepLink,
    myChartTestResults:
      process.env.REACT_APP_MYCHART_TEST_RESULT ||
      settings.urlConfig.myChartTestResults,
    myChartTestResultsDeepLink:
      process.env.REACT_APP_MYCHART_TEST_RESULT_DEEPLINK ||
      settings.urlConfig.myChartTestResultsDeepLink,

    myChartGuestBillPay:
      process.env.REACT_APP_MYCHART_GUEST_PAY ||
      settings.urlConfig.myChartGuestBillPay,
    myChartPayBill:
      process.env.REACT_APP_MYCHART_PAY ||
      settings.urlConfig.myChartPayBill,
    myChartPayBillDeeplink:
      process.env.REACT_APP_MYCHART_PAY_DEEPLINK ||
      settings.urlConfig.myChartPayBillDeeplink,

    myChartGuestCostEstimate:
      process.env.REACT_APP_MYCHART_GUEST_ESTIMATE ||
      settings.urlConfig.myChartGuestCostEstimate,
    myChartCostEstimate:
      process.env.REACT_APP_MYCHART_ESTIMATE ||
      settings.urlConfig.myChartCostEstimate,
    myChartCostEstimateDeeplink:
      process.env.REACT_APP_MYCHART_ESTIMATE_DEEPLINK ||
      settings.urlConfig.myChartCostEstimateDeeplink,

    blockItUrl:
      process.env.REACT_APP_BLCOKIT_URL || settings.urlConfig.blockItUrl,
    blockItQuickCareUrl:
      process.env.REACT_APP_BLOCKIT_QUICKCARE_URL ||
      settings.urlConfig.blockItQuickCareUrl,
  },
  apiConfig: {
    ...settings.apiConfig,
    baseUrl: process.env.REACT_APP_API_URL || settings.apiConfig.baseUrl,
  },
  languageConfig: settings.languageConfig,
  // blockitConfig: {
  //   ...settings.blockitConfig,
  //   baseUrl:
  //     process.env.REACT_APP_BLCOKIT_URL || settings.blockitConfig.baseUrl,
  //   redirectUrl:
  //     process.env.REACT_APP_BLOCKIT_QUICKCARE_URL ||
  //     settings.blockitConfig.redirectUrl,
  // },
  pageNames: settings.pageNames,
  profileFields: settings.profileFields,
};

export default config;
