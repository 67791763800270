import i18n from 'i18next';
import backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from '../localizations/translationEN.json';
import translationZH from '../localizations/translationZH.json';
import translationES from '../localizations/translationES.json';
import translationAR from '../localizations/translationAR.json';
import config from '../config/config';

const getResource = (lng) => {
  let contents = null;
  switch (lng) {
    case 'es':
      contents = translationES;
      break;
    case 'ar':
      contents = translationAR;
      break;
    case 'zh':
      contents = translationZH;
      break;
    default:
      contents = translationEN;
  }
  return contents;
};

const getResourcesApi = (lng) => {
  return new Promise((resolve, reject) => {
    //here we call a different api based on selected language
    //const fileName = lng === 'en' ? translationEN : translationES;
    const contents = getResource(lng);
    resolve(contents);
    reject(new Error('Error retrieving i18next translation resource'));
  });
};

export const getResources = {
  loadPath: '{{lng}}|{{ns}}',
  request: (options, url, payload, callback) => {
    try {
      const [lng] = url.split('|');
      getResourcesApi(lng).then((response) => {
        // console.debug('Retrieved resource', response);
        console.debug('Retrieved i18next translation resource');
        callback(null, { data: response, status: 200 });
      });
    } catch (e) {
      console.log(e);
      callback(null, { status: 500 });
    }
  },
};

// console.debug('Initializing i18n ...');
const mymhI18n = i18n
  .use(LanguageDetector)
  .use(backend)
  .use(initReactI18next);

//Note Suspense somehow does not work as expected in FireFox. So we have to force js to block the process until the translation becomes ready
// await mymhI18n.init({
//   debug: true,
//   backend: getResources,
//   ns: ['translation'],
//   fallbackLng: config.languageConfig.defaultLanguageName,
//   load: 'languageOnly',
//   interpolation: {
//     escapeValue: false, // not needed for react as it escapes by default
//     formatSeparator: ',',
//   },
//   react: { useSuspense: true },
// });

export const initI18n = (fallbackLang, useSuspense) => {
  const lang = fallbackLang || config.languageConfig.defaultLanguageName;
  console.debug(`Initialized i18Next with fallbackLng = ${lang}`);

  return mymhI18n.init({
    debug: false,
    backend: getResources,
    ns: ['translation'],
    fallbackLng: lang,
    load: 'languageOnly',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      formatSeparator: ',',
    },
    react: { useSuspense: (useSuspense != undefined && useSuspense != null) ? useSuspense : true },
  });
};

initI18n();

export default mymhI18n;
