import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getCurrentLanguge } from '../../services/languageService';
import { LanguageState, State } from '../state';
import { LogVerbose } from '../../services/utilities';

// import { getPreferredLanguge } from '../../services/languageService';

const initialState: LanguageState = {
  language: getCurrentLanguge(),
};

export const languageSlice = createSlice({
  name: 'language',
  initialState: initialState,
  reducers: {
    updateLanguage: (state: LanguageState, action: PayloadAction<string | undefined>) => {
      //return { ...state, language: action.payload };

      //@@@ Redux Toolkit allows us to write "mutating" logic in reducers. It
      //@@@ doesn't actually mutate the state because it uses the immer library,
      //@@@ which detects changes to a "draft state" and produces a brand new
      //@@@ immutable state based off those changes
      LogVerbose(`Redux: Switching LanguageState from ${state.language} to ${action.payload}`);
      state.language = action.payload; //@@@
      LogVerbose(`Redux: Switched LanguageState to: ${state.language}`);
    },
  },
});

export const { updateLanguage } = languageSlice.actions;
export const selectLanguage = (state: State): string | undefined => state.language.language;
export default languageSlice.reducer;
