import './getCareNow.scss';

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import config from '../../config/config';
import { selectLanguage } from '../../reduxToolkit/features/languageSlice';
import {
    ContentDictionary, getContentDictionaryInCurrentLanguage, loadGetCareNowDetails
} from '../../services/contentService';
import { isMobileApp, LogVerbose } from '../../services/utilities';
import { LocationDetails } from '../../types/interfaces';
import Navigation from '../shared/Navigation/Navigation';
import MyMhSpinner from '../shared/Spinner/MyMhSpinner';
import GetCareCard from './GetCareCard/GetCareCard';

// import MyMhBanner from '../MyMhBanner/MyMhBanner';

const GetCareNow = (): JSX.Element => {
  const { pageNames } = config;
  const language = useSelector(selectLanguage);
  const [contentDictionary, setContentDictionary] =
    useState<ContentDictionary | null>(null);

  const [getCare, setGetCare] = useState<LocationDetails[]>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    LogVerbose(`GetCareNow: language = ${language}`);
    loadGetCareNowDetails()
      .then((data) => {
        setGetCare(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
    const content = getContentDictionaryInCurrentLanguage('getQuickCare');
    if (!content) console.warn('Failed to load the content dictionary.');

    setContentDictionary(content);
  }, [language]);

  // console.log(getCare);

  const history = useHistory();

  return (
    <div className='w-100 h-100 row'>
      <h2 className='d-none'>Get Care Now</h2>
      <div className={`p-0 col-12 getCareNow ${isMobileApp() ? 'mobile-app' : ''}`}>
        <Navigation
          navigationTitle={
            contentDictionary?.getQuickCare || pageNames.getCareNow
          }
          showBackButton={true}
          showHomeButton={true}
          onBackButtonClicked={() => history.push(config.urlConfig.home)}
        />

        <div className='getCareNow-container'>
          <div className='getCareNow-header'>
            <span>{contentDictionary?.options}</span>
          </div>
          {isLoading && <MyMhSpinner />}
          <div className='getCareNow-body'>
            <div className='container'>
              <div className='row'>
                {getCare &&
                  getCare!.length > 0 &&
                  getCare?.map((g, index) => (
                    <GetCareCard key={index} getCareItem={g} />
                  ))}
              </div>
            </div>
          </div>
          <div className='getCareNow-footer'>
            <span className='row getCareNow-disclaimer'>{contentDictionary?.footDisclaimer}</span>
            <span>
              {contentDictionary?.footNote}{' '}
              <a href={contentDictionary?.footNoteLink}>
                {contentDictionary?.footNoteLinkText}
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetCareNow;
